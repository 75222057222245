body{
  background-color: $light-gray;
}

@mixin vertical-align($position: relative) {
  position: $position;
  top: 50%;
  -webkit-transform: translateY(-50%);
  -ms-transform: translateY(-50%);
  transform: translateY(-50%);
}

@mixin placeholder {
  &::-webkit-input-placeholder {@content}
  &:-moz-placeholder           {@content}
  &:-ms-input-placeholder      {@content}
  &::-moz-placeholder          {@content}
   ::-webkit-input-placeholder {@content}
   :-moz-placeholder           {@content}
   :-ms-input-placeholder      {@content}
   ::-moz-placeholder          {@content}
}

@mixin transition($transition...) {
  -webkit-transition: $transition;
  -moz-transition:    $transition;
  -ms-transition:     $transition;
  -o-transition:      $transition;
  transition:         $transition;
}

.noselect {
-webkit-touch-callout: none; /* iOS Safari */
  -webkit-user-select: none; /* Safari */
   -khtml-user-select: none; /* Konqueror HTML */
     -moz-user-select: none; /* Firefox */
      -ms-user-select: none; /* Internet Explorer/Edge */
          user-select: none; /* Non-prefixed version, currently supported by Chrome and Opera */
}

.flex-row{
  display: flex;
  justify-content: space-between;
  .form-group.input-field{
    position: relative;
    display: flex;
    border: 1px solid $medium-brown;
    flex-grow: 1;
    margin: 0 20px;
    max-height: 39px;
    input{
      width: 100%;
      padding: 10px 17px;
      border-radius: 2px;
      &:focus{
        outline: 5px auto $medium-blue !important;
      }
    }
  }
  span.fa.fa-search{
    position: absolute;
    z-index: 1;
    top: 12px;
    right: 15px;
  }
}

// Padding

.no-p{
  padding: 0px;
}

.p-t-xs{
  padding-top: 10px;
}

.p-t-sm{
  padding-top: 20px;
}

.p-t-md{
  padding-top: 40px;
}

.p-t-lg{
  padding-top: 80px;
}

.p-t-xl{
  padding-top: 160px;
}

.p-t-none{
  padding-top: 0px !important;
}

.p-r-xs{
  padding-right: 10px;
}

.p-r-sm{
  padding-right: 20px;
}

.p-r-md{
  padding-right: 40px;
}

.p-r-lg{
  padding-right: 80px;
}

.p-r-xl{
  padding-right: 160px;
}

.p-r-none{
  padding-right: 0px;
}

.p-b-xs{
  padding-bottom: 10px;
}

.p-b-sm{
  padding-bottom: 20px;
}

.p-b-md{
  padding-bottom: 40px;
}

.p-b-lg{
  padding-bottom: 80px;
}

.p-b-xl{
  padding-bottom: 160px;
}

.p-b-none{
  padding-bottom: 0px;
}

.p-l-xs{
  padding-left: 10px;
}

.p-l-sm{
  padding-left: 20px;
}

.p-l-md{
  padding-left: 40px;
}

.p-l-lg{
  padding-left: 80px;
}

.p-l-xl{
  padding-left: 160px;
}

.p-l-none{
  padding-left: 0px;
}

.p-xs {
  padding: 10px 0;
}

.p-sm {
  padding: 20px 0;
}

.p-md {
  padding: 40px 0;
}

.p-lg {
  padding: 80px 0;
}

.p-xl {
  padding: 160px 0;
}

// Margin

.no-m{
  margin: 0px;
}

.m-sm {
  margin: 20px!important;
}

.m-xs {
  margin: 10px!important;
}

.m-t-xs{
  margin-top: 10px;
}

.m-t-sm{
  margin-top: 20px;
}

.m-t-md{
  margin-top: 40px;
}

.m-t-lg{
  margin-top: 80px;
}

.m-t-xl{
  margin-top: 160px;
}

.m-t-none{
  margin-top: 0px;
}

.m-r-xs{
  margin-right: 10px;
}

.m-r-sm{
  margin-right: 20px;
}

.m-r-md{
  margin-right: 40px;
}

.m-r-lg{
  margin-right: 80px;
}

.m-r-xl{
  margin-right: 160px;
}

.m-r-none{
  margin-right: 0px;
}

.m-b-xs{
  margin-bottom: 10px;
}

.m-b-sm{
  margin-bottom: 20px;
}

.m-b-md{
  margin-bottom: 40px;
}

.m-b-lg{
  margin-bottom: 80px;
}

.m-b-xl{
  margin-bottom: 160px;
}

.m-b-none{
  margin-bottom: 0px;
}

.m-l-xs{
  margin-left: 10px;
}

.m-l-sm{
  margin-left: 20px;
}

.m-l-md{
  margin-left: 40px;
}

.m-l-lg{
  margin-left: 80px;
}

.m-l-xl{
  margin-left: 160px;
}

.m-l-none{
  margin-left: 0px;
}

.shade {
  background: $shade;
}

.quiet {
  font-size:.9em;
  color:$medium-brown!important;
}

.flex-center {
  justify-content: center;
}

.full-width {
  width:100%;
}

.wrap-flex-row {
  display:flex;
  flex-direction:row;
  flex-wrap: wrap;
  
}