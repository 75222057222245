//Custom
$line-height: 26px;

.rw-combobox {
  &.rw-widget {
    border-width: 0 !important;
  }
  &:hover {
    background-color: white !important;
    border-color: none !important;
  }
  &:active {
    &,
    &.rw-state-focus {
      box-shadow: none !important;
    }
  }
  .rw-popup-container {
    left: 0 !important;
    right: 0 !important;
    .rw-popup {
      top: 6px;
      left: 0;
      right: 0;
    }
  }
  .rw-input {
    height: 24px;
    line-height: $line-height !important;
    box-shadow: none !important;
    margin-top: 3px;
    padding-left: 13px;
    color: $black;
    // padding-top: 3px !important;
  }
  .rw-btn {
    color: $medium-brown !important;
    line-height: $line-height;
    // padding-top: 3px;
    .rw-i-search {
      padding-top: 8px;
    }
  }
}
