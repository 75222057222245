@media only screen and (max-width: 450px) {
  .add-pet-card {
    width: 100% !important;
    transition: all .3s ease-in-out;
    margin: 20px 0 0 0 !important;
    &:last-child {
      margin-bottom: 20px !important;
    }
  }

  #overlay {
    button {
      width: 100%;
      box-sizing: border-box;
    }
    h5:hover { color: black; text-decoration: none; }
  }

  .content-container {
    margin: 5px;
  }
}

@media only screen and (max-width: 1000px) {
  .form-infobox {
    border-radius: 0;
  }
}

@media only screen and (max-width: 768px) {
  .row-modal {
    margin: 0;
    padding: 0 15px;
  }

  .user-list-item:not(:first-child) {
    min-height: 110px !important;
  }

  .user-list-notes {
    margin-top: 10px !important;
    color: $medium-brown;
  }

  .user-list-details-btn {
    margin-top: 5px;
  }

  .cc-current-wrapper .button-column button {
    background-color: transparent !important;
    color: $medium-brown !important;
    &:hover {
      color: $dark-brown !important;
    }
  }
}
