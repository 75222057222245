// Nav

.select-city{
  max-width: 200px;
  display: inline-block;
  padding: 20px 0 0 50px;
}

nav.nav {
  background-color: $medium-blue;
  border: none;
  margin-bottom: 0px;
  transition: all .5;
  height: 70px;
  font-size: 15px;
  a.new-appointment-link-button{
    display: inline;
  }
}

ul.nav.navbar-nav li ul li a {
  color: #555555;
}

ul.nav.navbar-nav li.dropdown.open a.dropdown-toggle {
  background-color: #2b7a78;
}

ul.nav.navbar-nav {
  font-family: $light-font;
  color: $white;
  height: 70px;
  padding: 0 0 0 10px;
  li a{
    padding: 25px 20px;
    color: $white;
    &:hover, &:focus, &:active{
      background-color: $dark-blue;
    }
  }
  li a.active-link{
    padding: 25px 20px;
    color: $white;
    &:hover, &:focus, &:active{
      background-color: $dark-blue;
    }
  }
  li a.new-appointment-link-button {
    &:hover, &:focus, &:active {
      background-color: transparent;
    }
  }
  li.active{
    border-bottom: 3px solid $medium-blue;
    a{
      background-color: transparent;
      font-weight: 500;
      margin-bottom: -3px;
      &:hover, &:focus, &:active{
        background-color: $dark-blue;
      }
    }
  }
}

.dropdown-menu.nav-client {
  ul a {
    padding: 0 !important;
  }
  li { color: $gray; }
  li:hover {
    span { color: white; }
    color: $white;
    background-color: $medium-blue;
  }
}

.navbar-brand {
  padding-left: 15px;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.user-icon {
    padding: 0px 10px;
    color: $medium-blue;
}

.logo {
    width: 100%;
    max-width: 135px;
    height: auto;
}

span.chevron {
    color: $white;
    font-size: 10px;
    position: relative;
    display: inline-block;
    top: -2px;
    right: 0;
    padding: 0 10px;
}

span.user-icon {
    color: $white;
}

.navbar-toggle {
  margin-top: 15px;
  border-color: $medium-brown !important;
  transition: .5s all;
  span.icon-bar{
    background-color: $medium-brown !important;
  }
  &:hover{
    background-color: $light-brown !important;
  }
}

.dropdown {
  background-color: transparent;
}

li.dropdown.open a.dropdown-toggle {
  background-color: $dark-blue;
  &:hover {
    background-color: $dark-blue;
  }
}

.mobile-navigation {
  margin: 0 15px;
  background-color: white;
  padding: 15px;
  // box-shadow: 0 0 10px rgba(0, 0, 0, .2);
  // border-radius: 3px;

  .new-appointment-link-button {
    margin-bottom: 20px;
    button { margin-top: 0; }
    &:hover { background-color: transparent; }
  }

  li b { color: $medium-blue; }
  .nav-quicklinks {
    a {
      display: inline;
      color: $medium-brown;
      &:hover {
        color: $black;
        background-color: transparent;
      }
    }
  }
}

@media (max-width: 968px){
  .navbar{
    .navbar-header{
      height:70px;
      width:30%;
      .navbar-brand{
        padding:15px 8px 0px 8px;
      }
    }
    .select-city{
      padding: 15px 8px;
    }
    ul.nav.navbar-nav{
      padding:0;
      li a{
        padding:25px 8px;
      }
    }
  }
}

@media only screen and (max-width:668px){
  .navbar.navbar-default{
    .navbar-header{
      width:100%;
      margin:0;
    }
  }
}
