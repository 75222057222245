h1{
  font-family: 'Nunito';
  font-weight: 100;
  color: $black;
  font-size: 18px;
  line-height: 28px;
  margin: 10px 0;
  @extend .noselect;
}

h2{
  font-family: 'Nunito';
  font-weight: 100;
  color: $medium-blue;
  font-size: 18px;
  line-height: 28px;
  margin: 10px 0;
  @extend .noselect;
}

h3{
  font-family: 'Nunito';
  font-weight: 300;
  font-size: 16px;
  color: $medium-blue;
  line-height: 26px;
  @extend .noselect;
}

h4{
  font-family: 'Nunito';
  font-weight: 300;
  font-size: 15px;
  color: $medium-brown;
  line-height: 26px;
  @extend .noselect;
  &.med-name{
    display: inline-block;
  }
}

h5{
  font-family: 'Nunito';
  font-weight: 300;
  font-size: 14px;
  color: $medium-brown;
  line-height: 26px;
  @extend .noselect;
}

.minor-heading {
  color: $medium-brown;
  text-transform: uppercase;
  font-size: 12px;
  font-weight: 500;
}

// Font icon class name for react-widgets components
.rw-i-caret-down {
  color: $light-brown;
}

.dark-brown {
  color: $dark-brown;
}
