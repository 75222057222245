//Custom
$line-height: 26px;

.rw-dropdownlist {
  border-width: 0 !important;
  &:hover {
    background-color: white;
    border-color: none !important;
  }
  &:active {
    &,
    &.rw-state-focus {
      box-shadow: none !important;
    }
  }
  .rw-popup-container {
    left: 0 !important;
    right: 0 !important;
    .rw-popup {
      top: 0px;
      left: 0;
      right: 0;
    }
  }
  .rw-input {
    height: 24px;
    line-height: $line-height !important;
    box-shadow: none !important;
    margin-top: 3px;
    padding-left: 10px;
    color: $black;
    @extend .noselect;
    .rw-placeholder {
      color: $medium-brown;
    }
  }
  .rw-btn {
    color: $medium-brown;
    line-height: $line-height;
    .rw-i-search {
      padding-top: 8px;
    }
  }
}
