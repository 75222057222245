$gray-base:              #000 !default;
$gray-darker:            lighten($gray-base, 13.5%) !default; // #222
$gray-dark:              lighten($gray-base, 20%) !default;   // #333
$gray:                   lighten($gray-base, 33.5%) !default; // #555
$gray-light:             lighten($gray-base, 46.7%) !default; // #777
$gray-lighter:           lighten($gray-base, 93.5%) !default; // #eee


$text-color:                $gray-dark !default;

$border-radius:             4px !default;
$border-radius-sm:          3px !default;

$btn-bg:                    #fff !default;
$btn-color:                 #333 !default;
$btn-border:                #ccc !default;

$state-bg-select:           darken($btn-border, 12%) !default;
$state-border-select:       darken($btn-border, 12%) !default;
$state-color-select:        #333 !default;

$state-bg-hover:            darken($btn-bg, 10%) !default;
$state-border-hover:        darken($btn-border, 12%) !default;
$state-color-hover:         #333 !default;

$widget-bg:                 #fff !default;
$widget-border:             #ccc !default;
$line-height:               1.429em !default;

$state-bg-focus:            $widget-bg !default;
$state-border-focus:        #66afe9 !default;
$state-color-focus:         #333 !default;

$input-color:               $gray !default;
$input-height:              2.286em !default;
$input-padding:             0.429em 0.857em !default;
$input-bg:                  #fff !default;
$input-bg-disabled:         $gray-lighter !default;

$input-bg-gradient:         none !default;
$input-color-placeholder:   #999 !default;

$input-border-radius:       $border-radius !default;
$input-border:              #ccc !default;
$input-border-width:        1px !default;

$input-bg-hover:            $gray-lighter !default;
$input-border-hover:        $input-border !default;

$input-border-focus:        $state-border-focus !default;

$dropdown-bg:               $input-bg !default;
$dropdown-border:           $input-border !default;

$list-bg-hover:             $state-bg-hover !default;
$list-border-hover:         $state-border-hover !default;

$popup-bg:                  $widget-bg !default;
$popup-border:              $widget-border !default;
$popup-zindex:              1005 !default;
