.gray-table {
  margin: 5px 10px;
  background-color: #d5d5d5;
  border-radius: 3px;
  border: 1px solid #3aafa9;
  .row-wrapper { width: 100%; }
  .parent-row, .parent-header {
    display: inline-grid;
    grid-template-columns: 150px auto 200px;
    width: 100%;
    .actions {
      display: flex;
      align-items: center;
    }
  }
  .sub-table {
    margin-left: 150px;
    margin-right: 30px;
    background-color: white;
  }
  .animation-thing { /*couldn't get this to work :(*/
    -webkit-transition: 2s all ease; /* Safari */
    transition: 2s all ease;
  }
  .child-header, .child-row {
    display: flex;
    justify-content: space-around;
    align-items: center;
    padding: 5px;
  }
  .cells, .headings {
    display: inline-grid;
    grid-template-columns: repeat(4, .25fr);
    padding: 5px;
    div {
      display: flex;
      justify-content: center;
    }
  }
  .parent-header {
    font-weight: bold;
    font-size: 1.3em;
  }
  .parent-row {
    font-size: 1.2em;
  }
  .child-header {
    font-weight: bold;
    font-size: 1.1em;
  }
}

