.user-list-flags {
  display: inline-flex;
}

.flags {

  &-pet {
    &-red {
      background-color: $alert-dark-red;
      border: 1px solid $alert-dark-red;
      font-size: 12px;
      border-radius: 9px;
      text-align: center;
      line-height: 18px;
      font-weight: normal;
      margin: 0 5px 0 0;
      width: 18px !important;
      height: 18px !important;
      min-width: 18px !important;
      min-height: 18px !important;
    }

    &-yellow {
      background-color: $classification-yellow;
      border: 1px solid $classification-yellow;
      font-size: 12px;
      border-radius: 9px;
      text-align: center;
      line-height: 18px;
      font-weight: normal;
      margin: 0 5px 0 0;
      width: 18px !important;
      height: 18px !important;
      min-width: 18px !important;
      min-height: 18px !important;
    }
  }

  &-user {
    &-billings {
      color: $alert-dark-red;
    }

    &-active {
      &-green {
        background-color: $medium-blue;
        display: inline-block;
        border-radius: 3px;
        text-align: center;
        line-height: 6px;
        font-weight: normal;
        margin: 8px 5px 0 0;
        width: 6px !important;
        height: 6px !important;
        min-width: 6px !important;
        min-height: 6px !important;
        float: left;
      }
    }
  }
}
