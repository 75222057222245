
@font-face {
  font-family: 'RwWidgets';
  src: url('#{$rw-font-path}/fontawesome-webfont.eot?v=#{$rw-version}');
  src: url('#{$rw-font-path}/fontawesome-webfont.eot?#iefix&v=#{$rw-version}') format('embedded-opentype'),
    url('#{$rw-font-path}/fontawesome-webfont.woff?v=#{$rw-version}') format('woff'),
    url('#{$rw-font-path}/fontawesome-webfont.ttf?v=#{$rw-version}') format('truetype'),
    url('#{$rw-font-path}/fontawesome-webfont.svg?v=#{$rw-version}#fontawesomeregular') format('svg');
  font-weight: normal;
  font-style: normal;
}


.#{$rw-css-prefix} {
  display: inline-block;
  font-family: RwWidgets;
  font-style: normal;
  font-weight: normal;
  line-height: 1em;
  font-variant: normal;
  text-transform: none;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.#{$rw-css-prefix}-caret-down:before { content: '\f078'; }
.#{$rw-css-prefix}-caret-up:before { content: '\f077'; }
.#{$rw-css-prefix}-caret-left:before { content: '\f053';  }
.#{$rw-css-prefix}-caret-right:before { content: '\f054'; }

.#{$rw-css-prefix}-clock-o:before { content:'\f017'; }
.#{$rw-css-prefix}-calendar:before { content: '\f073'; }
.#{$rw-css-prefix}-search:before { content: '\f002'; }
