div.zone-badge{
  font-family: 'Nunito';
  font-weight: 500;
  cursor: pointer;
  display: inline-block;
  &.tiny{
    font-size: 10px;
    border-radius: 50%;
    line-height: 24px;
    text-align: center;
    padding: 0;
    margin-left: 10px;
    height: 22px;
    width: 22px;
  }
  &.medium{
    font-size: 12px;
    border-radius: 17px;
    text-align: center;
    line-height: 34px;
    font-weight: normal;
    margin: 0 5px 0 0;
    width:  34px !important;
    height: 34px !important;
    min-width: 34px !important;
    min-height: 34px !important;
  }
  &.large{
    font-size: 16px;
    border-radius: 50px;
    padding: 13px 19px;
    font-weight: 600;
    margin: 0 2px;
  }
}

div.zone-one{
  font-family: 'Nunito';
  font-weight: 600;
  color: #FFF;
  font-size: 16px;
  background-color: #AD8DEA;
  border-radius: 50px;
  padding: 13px 20px;
}

div.zone-two{
  font-family: 'Nunito';
  font-weight: 600;
  color: #FFF;
  font-size: 16px;
  background-color: #E985C4;
  border-radius: 50px;
  padding: 13px 20px;
}

div.zone-three{
  font-family: 'Nunito';
  font-weight: 600;
  color: #FFF;
  font-size: 16px;
  background-color: #5CCCB3;
  border-radius: 50px;
  padding: 13px 20px;
}

div.zone-four{
  font-family: 'Nunito';
  font-weight: 600;
  color: #FFF;
  font-size: 16px;
  background-color: #57A8E5;
  border-radius: 50px;
  padding: 13px 20px;
}

div.zone-five{
  font-family: 'Nunito';
  font-weight: 600;
  color: #FFF;
  font-size: 16px;
  background-color: #F27C7C;
  border-radius: 50px;
  padding: 13px 20px;
}

div.zone-six{
  font-family: 'Nunito';
  font-weight: 600;
  color: #FFF;
  font-size: 16px;
  background-color: #FCB674;
  border-radius: 50px;
  padding: 13px 20px;
}

div.zone-seven{
  font-family: 'Nunito';
  font-weight: 600;
  color: #FFF;
  font-size: 16px;
  background-color: #F9D861;
  border-radius: 50px;
  padding: 13px 20px;
}

div.zone-eight{
  font-family: 'Nunito';
  font-weight: 600;
  color: #FFF;
  font-size: 16px;
  background-color: #AFD377;
  border-radius: 50px;
  padding: 13px 20px;
}
