//Full Pet Card (For Scheduler and Calendar Day View)
.petCardCondensedWrapper{
  position: relative;
  .closeButton{
    font-size: 16px;
    width: 20px;
    height: 20px;
    position: absolute;
    top: 0;
    right: 0;
  }
  .closeButton:hover{
    background-color: darken($pending, 20);
  }
}

.petcard-hover{
  position: absolute;
  top: -50px;
  background-color: $white;
  z-index: 999;
  left: -50px;
  width: 250px;
}
.pet-card{
  margin: 10px auto;
  min-height: 200px;
  .PetCard-selected-text {
    position:static;
    top:0;
    text-align: center;
    font-weight: bold;
    opacity: 1;
    padding: 20px;
    transition: all .3s ease-in;
    .default-text { display: inline; }
    .cancel-text { display: none; }
    &.close {
      width: 100%;
      display: none;
    }

    &:hover {
      cursor: pointer;
      .default-text { display: none; }
      .cancel-text {
        display: inline;
        &:hover { color: $alert-dark-red; }
      }
    }
  }

  .pet-card-detail{
    display: flex;
    flex-direction: column;
    position: relative;
    background-color: $white;
    border: 1px solid $light-brown;
    border-radius: 6px;
    // min-width: 289px;
    width: 100%;
    min-height: 200px;
    justify-content: space-between;
    cursor: pointer;
    z-index: 1;

    &:hover {
      box-shadow: 5px 5px 10px rgba($gray, 0.2);
      transition: .3s all ease-in;
    }

    &-compact {
      min-width: 0;

      &.selected.open {
        &:hover {
          box-shadow: 0px 5px 10px rgba($gray, 0.2);
          transition: .3s all ease-in;
        }
      }
    }

    @extend .noselect;
    p.appointment-detail{
      font-family: $light-font;
      color: $dark-brown;
      font-weight: 500;
      font-size: 12px;
      margin: 2px 0;
    }
    .pet-card-annotations{
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      padding: 2px 6px 4px;
      p{
        display: inline-block;
        margin: 0;
        vertical-align: top;
      }
      .left-annotations{
        display: flex;
        align-items: center;
        div{
          display: flex;
          align-items: center;
          margin-right: 4px;
        }
      }
      .right-annotations{
        padding-top: 2px;
        p.appointment-detail{
          margin-top: 2px;
        }
      }
      .zone-number{
        margin-left: 4px;
      }
    }

    .pet-card-main{
      display: flex;
      flex-direction: row;
      padding: 5px 5px 5px 5px;

      .pet-image-wrapper{
        // width: 70px;
        display: flex;
        justify-content: center;
        margin: 0 10px 0 5px;
        .pet-img{
          position: relative;
          width: 50px;
          height: 50px;
          background-color: $light-brown;
          border-radius: 100px;
          overflow: hidden;
          background-size: cover;
        }
      }
      .condensed-client-info-wrapper{
        flex: 1;
        display: flex;
        flex-direction: row;
        align-items: stretch;
        width: 100px;
        line-height: 14px;
        vertical-align: middle;
        padding: 0 10px 0 0;
      }
      .client-info-wrapper{
        flex: 1;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: stretch;
        width: 100px;
        line-height: 14px;
        padding: 0 10px 0 0;

        .pet-name {
          font-family: $light-font;
          color: $black;
          font-size: 12px;
          margin: 5px 0;
          overflow: hidden;
          text-overflow: ellipsis;
          white-space: nowrap;
        }
        .client-name {
          font-family: $light-font;
          color: $black;
          font-size: 14px;
          margin: 0;
        }
        .scheduled-time {
          font-family: $light-font;
          font-weight: bold;
          color: $black;
          font-size: 14px;
          margin: 2px 0;
        }
      }
      .client-address-wrapper{
        display: flex;
        align-items: flex-end;
        flex-direction: column;
        justify-content: center;
        align-items: stretch;
        color: $black;
        font-size: 12px;
        max-width:85px;//not very flexboxy..
        overflow:hidden;
        p{
          margin: 0;
        }
      }
    }
    .pet-card-times{
      display: flex;
      .pet-card-time-cell{
        border-width: 1px;
        border-top-style: solid;
        padding: 6px 9px;
        p{
          margin: 0;
        }
        p.pet-card-time-label{
          font-size: 10px;
          text-transform: uppercase;
          font-weight: bold;
        }
        &.left{
          flex: 1;
        }
        &.middle{
          flex: 1.1;
          border-width: 1px;
          border-left-style: solid;
          border-right-style: solid;
        }
        &.right{
          flex: 0.7;
        }
      }
    }
    .appointment-detail-link{
      display: flex;
      justify-content: center;
      align-items: center;
      padding: 8px 0 6px 0;
      color: $white;
      text-align: center;
      font-weight: 500;
      font-size: 11px;
      border-bottom-width: 0;
      transition: 0.5s all;
      border-bottom-left-radius: 5px;
      border-bottom-right-radius: 5px;

      p{
        margin: 0;
      }
      &.completed{
        // background-color: $completed;
        background-color: $medium-blue;
        &:hover{
          // background-color: #EAA562;
          background-color: $dark-blue;
        }
      }
      &.in-progress{
        background-color: $in-progress;
        &:hover{
          background-color: #E56262;
        }
      }
      &.approved{
        background-color: $approved;
        &:hover{
          background-color: #96C061;
        }
      }
      &.pending{
        background-color: $pending;
        &:hover{
          background-color: #EAC54C;
        }
      }

      &.canceled{
        background-color: $change-requested;
      }

      &.change-requested{
        background-color: $change-requested;
        &:hover{
          background-color: #9370D3;
        }
      }
      &.selected{
        background-color: $selected;
        background-color: $dark-blue;
        opacity: 1;

        border-radius: 0;
        border:0;

        &:hover{
          background-color: #6FBDAF;
        }
      }
    }
    &.open{
      border-bottom-left-radius: 0;
      border-bottom-right-radius: 0;
    }
    &.completed{
      @extend .completed;
    }
    &.in-progress{
      @extend .in-progress;
    }
    &.approved{
      @extend .approved;
    }
    &.pending{
      @extend .pending;
    }
    &.change-requested{
      @extend .change-requested;
    }
    &.selected{
      @extend .selected;
    }
    &.canceled{
      @extend .canceled;
    }
  }
  .pet-card-drawer{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    z-index: -1;
    background-color: $light-gray;
    border-width: 0 1px 1px 1px;
    border-style: solid;
    border-color: $medium-brown;
    border-bottom-left-radius: 6px;
    border-bottom-right-radius: 6px;
    padding: 10px;
    font-family: $light-font;
    color: $black;
    font-size: 12px;

    // drawer-slide animation stuff
    $pet-card-drawer-height: 135px;
    height: $pet-card-drawer-height;
    margin-top: -$pet-card-drawer-height;
    @include transition(margin-top .2s ease-in);
    &.open{
      margin-top: 0px;

      form {
        width: 100%;
        .simple-checkbox-input {
          margin-left: 0;
        }
      }

    }
    .input-wrapper--form-control-wrapper-component {
      background-color: $white;
      border-color: $medium-brown;
      padding-bottom: 0;
      label{
        font-size: 11px;
      }
      .rw-datetimepicker.rw-widget{
        margin: 0;
      }
      .rw-select{
        margin-top: -3px;
      }
    }
    .pet-card-drawer-buttons{
      display: flex;
      justify-content: center;
      margin-top: 10px;
    }
  }
}

//Condensed Pet Card (For Calendar Week View)
.pet-card-condensed{
  position: relative;
  background-color: $white;
  border: 1px solid $light-brown;
  border-radius: 2px;
  margin: 0;
  padding: 5px 10px;
  display: flex;
  align-items: center;
  overflow: hidden;
  justify-content: space-between;
  height: 50px;
  cursor: pointer;
  @extend .noselect;
  p{
    &.pet-name, &.client-name, &.scheduled-time{
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }
  }
  .appointment-detail-wrapper{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: stretch;
    p{
      font-family: $light-font;
      color: $dark-brown;
      font-weight: 500;
      font-size: 12px;
      margin: 2px 0;
    }
  }
  .client-info-wrapper{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: stretch;
    width: 180px;
    .pet-name{
      font-family: $light-font;
      color: $black;
      font-size: 14px;
      margin: 2px 0;
    }
    .client-name{
      font-family: $light-font;
      color: $black;
      font-size: 12px;
      margin: 2px 0;
    }
    .scheduled{
      font-family: $light-font;
      color: $black;
      font-size: 12px;
      margin: 2px 0;
    }
  }
}

//Extra Condensed Pet Card (For Calendar Week View)
.pet-card-xtra-condensed{
  position: relative;
  background-color: $white;
  border: 1px solid $light-brown;
  border-radius: 2px;
  margin: 4px 6px;
  padding: 5px 10px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  min-height: 30px;
  cursor: pointer;
  @extend .noselect;
  p{
    margin: 0;
    color: $black;
    font-size: 12px;
    &.pet-names{
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }
  }
}

//Pet Card Color States
.completed{
  // background-color: rgba($completed, 0.16);
  background-color: lighten($medium-blue, 23%);
  border: 1px solid $medium-blue;
  color: $medium-blue;
}
.in-progress{
  // background-color: rgba($in-progress, 0.16);
  background-color: lighten($in-progress, 23%);
  border: 1px solid $in-progress;
  color: $in-progress;
}
.approved{
  // background-color: rgba($approved, 0.16);
  background-color: lighten($approved, 28%);
  border: 1px solid $approved;
  color: $approved;
}
.pending{
  // background-color: rgba($pending, 0.16);
  background-color: lighten($pending, 25%);
  border: 1px solid $pending;
  color: darken($pending, 25%);
}
.change-requested{
  // background-color: rgba($change-requested, 0.16);
  background-color: lighten($change-requested, 23%);
  border: 1px solid $change-requested;
  color: $change-requested;
}
.canceled {
  background-color: lighten($alert-light-red, 5);
  border: 1px solid $alert-light-red;
}
.selected{
  // background-color: rgba($selected, 0.25);
  background-color: lighten($selected, 24%);
  border: 1px solid $selected;
  color: $selected;

  .pet-card-detail, .pet-card-drawer {
    // display: none;
    opacity: .2;
    filter: alpha(opacity=20);

    &.open {
      display: inline-block;
      width: 100%;
      .pet-card-time-cell.middle,
      .client-address-wrapper
       { display: none; }
    }
  }
  .pet-card-drawer { display: none; }

  .pet-card-drawer.open {
    width: 100%;
  }

  &.open {
    display: inline-block;
    opacity: 1;
    width: 100%;
    filter: alpha(opacity=100);


  }
}

// Pet Image Border Styles for both PetCards and AppointmentPetField.pet-img{
.pet-img{
  &.yellow-border{
    box-shadow: 0px 0px 0px 3px $classification-yellow;
  }
  &.red-border{
    box-shadow: 0px 0px 0px 3px $classification-red;
  }
}
