.message-styles {
  border-radius: 0px;
  margin: 0;
  padding: 10px;
  height: 40px;
}

.message-container {
  width: 100%;
  position: fixed;
  top: 70px;
  left: 50%;
  padding: 0px;
  z-index: 1000;
  -webkit-transform: translate(-50%, 0);
  -ms-transform: translate(-50%, 0);
  transform: translate(-50%, 0);
  @extend .noselect;
  .error-message {
    @extend .message-styles;
    // border: 1px solid $alert-dark-red;
    color: $alert-dark-red;
    background-color: $alert-light-red;
  }
  .success-message {
    @extend .message-styles;
    // border: 1px solid $alert-dark-green;
    color: $alert-dark-green;
    background-color: $alert-light-green;
  }
  p {
    margin: 0;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
}

.message-container-special {
  width: 100%;
  position: fixed;
  top: 0px;
  left: 50%;
  padding: 0px;
  z-index: 2000;
  -webkit-transform: translate(-50%, 0);
  -ms-transform: translate(-50%, 0);
  transform: translate(-50%, 0);
  @extend .noselect;
  .error-message {
    @extend .message-styles;
    // border: 1px solid $alert-dark-red;
    color: $alert-dark-red;
    background-color: $alert-light-red;
  }
  .success-message {
    @extend .message-styles;
    // border: 1px solid $alert-dark-green;
    color: $alert-dark-green;
    background-color: $alert-light-green;
  }
  p {
    margin: 0;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
}

.message-enter {
  opacity: 0.01;
  margin-top: 0px;
}

.message-enter.message-enter-active {
  opacity: 1;
  margin-top: 0px;
  transition: opacity 500ms ease-in;
}

.message-leave {
  opacity: 1;
  margin-top: 7px;
}

.message-leave.message-leave-active {
  opacity: 0.01;
  margin-top: -40px;
  transition: opacity 200ms ease-out, margin-top 200ms ease-out;
}
