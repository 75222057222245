.btn {
  font-family: $light-font;
  font-weight: 400;
  outline: none;
  transition: .5s all;
  border-radius: 2px;
}

.navbar-btn {
  margin: 14px 5px 0 0;
}

@mixin btn-primary() {
  background: $medium-blue;
  cursor: pointer;
  border: 0px;
  padding: 11px 18px 9px;
  box-sizing: border-box;
  color: $white;
  font-weight: 600;
  font-size: 15px;
  margin: 5px;
  &:hover{
    background: $dark-blue;
    border: 0px;
    color: $white;
  }
  &:active, &:active:hover, &:active:focus{
    background-color: $dark-blue;
    border-color: $dark-blue;
    color: $white;
  }
  &:focus{
    background-color: $dark-blue;
    color: $white;
    border-color: $dark-blue;
  }
}

.btn-primary {
  @include btn-primary();
}

.btn-login {
  color: white;
  background-color: $medium-blue;
  border: 1px solid $medium-blue;
  padding: 9px 30px;
  &:hover{
    background-color: #5FA297;
    color: $white;
    border: 1px solid $medium-blue;
  }
  &:active{
    background-color: $medium-blue;
    background-image: none;
  }
}

.btn-secondary {
  color: $medium-blue;
  background-color: transparent;
  border: 1px solid $medium-blue;
  padding: 11px 18px 9px;
  border-radius: 100px;
  font-weight: 600;
  font-size: 15px;
  &:hover{
    background-color: $medium-blue;
    color: $white;
    border: 1px solid $medium-blue;
  }
  &:active{
    background-color: $light-blue;
    background-image: none;
  }
  &:focus{
    color: $medium-blue;
  }
  &:focus:hover{
    color: $white;
  }

  &.pagination {
    border-radius: 50% !important;
    width: 42px;
    span.fa {
      padding: 0 !important;
      line-height: 40px;
      text-align: center;
      font-size: 16px !important;
      background-color: transparent;
      width: 40px;
      height: 40px;
    }
  }

  &.navbar-btn {
    color: $dark-blue;
    background-color: $white;
    border: none;
    &:hover {
      color: $dark-blue;
      background-color: rgba(255, 255, 255, 0.75);
    }
  }
}

.btn-deactivate, .btn-discard-changes {
  border-color: $alert-light-red;
  color: $alert-dark-red;
  &:hover {
    background-color: $alert-light-red;
    border-color: $alert-light-red;
    color: $white;
  }
}

.btn-secondary.pagination{
  padding: 0;
  span.fa{
    padding: 15px;
    font-size: 12px;
  }
}

.btn-tertiary{
  color: $medium-brown;
  background-color: $white;
  border: 1px solid $medium-brown;
  padding: 9px 30px;
  &:hover{
    background-color: $light-brown;
    color: $dark-brown;
    border: 1px solid $medium-brown;
  }
  &:active{
    background-color: $medium-blue;
    background-image: none;
  }
}

.btn-tertiary.selected{
  background-color: $light-brown;
  font-weight: 500;
  color: $dark-brown;
  border: 1px solid $dark-brown;
}

.btn-small{
  border-width: 1px;
  border-style: solid;
  border-radius: 2px;
  padding: 4px 16px;
  margin: 0 6px;
  font-size: 12px;
  .fa{
    margin-right: 4px;
  }
}

.btn-green{
  border-color: $alert-dark-green;
  background-color: $alert-light-green;
  color: $alert-dark-green;
}

.btn-red{
  border-color: $alert-dark-red;
  background-color: $alert-light-red;
  color: $alert-dark-red;
}

.btn-lg {
  border-radius: 2px;
  padding: 15px 50px;
  margin: 10px 0;
  transition: .5s all;
}

.btn-table{
  border: 1px solid $medium-brown;
  font-family: $light-font;
  font-size: 14px;
  color: $medium-brown;
  border-radius: 2px;
  background-color: $white;
  margin-right: 15px;
  padding: 8px 42px;
  transition: .5s all;
  float: right;
  &:hover{
    color: $white;
    background-color: $medium-brown;
  }
}

.btn-group.filter{
  display: inline-block;
  padding: 0px;
  .btn.btn-secondary{
    background-color: $white;
    border: $medium-brown;
    color: $medium-brown;
    padding: 10px 20px;
  }
  .btn.btn-secondary.selected{
    background-color: $medium-brown;
    border: $medium-brown;
    color: $white;
    padding: 10px 20px;
  }
}

.plus-button {
  display:inline-block;
  color: $dark-brown;
  border-bottom:1px solid $dark-brown;
  span {
    font-weight:bold;
  }
}

.add-pet-btn {
  position: absolute;
  top: 21px;
  right: 20px;
}

.normal-size-btn {
  padding: 11px 18px 9px;
}

.btn-slack {
  background-color: #EBB424;
  color: $white;
  margin: 5px;
}

.moveVisits{
  width: auto !important;
  padding: 2px 4px;
  float: right;
  margin: 0;
  margin-left: 4px;
  height: 25px;
}

.movingVisits{
  float: right;
  width: 25%;
}

.rw-popup-container{
  z-index: 1801;
}
.rw-list{
  z-index: 1801;
}

.stackerz{
  display: inline-block;
  float: right;
  height: 28px;
  line-height: 28px;
  vertical-align: middle;
  margin-right: 15px;
  label{
    margin-right: 10px;
  }
}

.dropdownThing{
  height: 30px;
  padding: 0px 30px;
  text-align: right;
  margin-bottom: 0 !important;
  h5{
    font-size: 16px;
    cursor: pointer;
    margin: 0;
    line-height: 30px;
    vertical-align: middle;
  }
  i{
    float: right;
    font-size: 16px;
    cursor: pointer;
    padding-left: 10px;
    line-height: 30px;
    vertical-align: middle;
  }
}

.twistigoo{
  transition: transform 150ms ease;
}

.twistigooDown{
  transform: rotate(-180deg);
}
.helloThere{
  height: 165px;
  padding: 10px 20px;
  transition: .5s all ease;
}
.goodbye{
  height: 0;
  overflow: hidden;
  transition: .5s all ease;
  margin-bottom: 0 !important;
}

.maNamesAgain{
  padding: 45px 0 0 15px;
}

.renderSpecial{
  // height: 300px;
  // background: blue;
  padding: 10px;
  border-bottom: 1px solid $light-gray;
  button{
    float: right;
    height: auto;
    padding: 2px 4px;
    margin: 20px 0;
  }
  .breakRow{
    button{
      margin: 0;
    }
    height: 35px;
    border-radius: 4px;
    padding: 5px 0;
    border-bottom: 1px solid $light-gray;
    transition: .5s background-color ease;
  }
}

.removeGhost{
  text-align: center;
}

.ghosty{
  border-bottom: 1px solid $light-gray;
  height: 35px;
  line-height: 35px;
  vertical-align: middle;
  margin-bottom: 0;
}

.runButtons{
  padding: 15px 25px;
  button{
    float: right;
  }
  .lockBtn{
    float: left;
  }
}

.showStack{
  width: 400px;
  background: $white;
  position: absolute;
  top: -10px;
  z-index: 1805;
  color: $black;
  border-radius: 5px;
  max-height: 300px;
  overflow-y: scroll;
  cursor: default;
  .showStackCol{
    height: 100%;
    line-height: 35px;
    vertical-align: middle;
    font-size: 12px;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
  }
  .sweetStack{
    height: 35px;
    cursor: pointer;
    border-bottom: 1px solid $light-gray;
    margin-left: 0;
    margin-right: 0;
  }
  .sweetStack:hover{
    background: $light-gray;
  }
  .showStackClose{
    height: 27px;
    background: $light-gray;
    span{
      position: absolute;
      right: 8px;
      top: 5px;
      cursor: pointer;
      color: $black;
      font-size: 14px;
    }
  }
  .nonono{
    padding: 0;
    margin-left: 0;
    margin-right: 0;
  }
}

.schedulingAlertModal{
  background-color: $white;
  z-index: 2000;
  div{
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 10px;
  }
}

.dirty{
  background-color: $light-blue;
  border: none;
}

.breakDrop{
  display: inline-block;
}
.traffic{
  position: absolute;
  right: 60px;
  bottom: 23px;
  background: $white;
  height: 22px;
  padding: 2px 4px;
  label{
    line-height: 22px;
    vertical-align: middle;
    margin-right: 5px;
    margin-bottom: 0;
  }
}

.stackerBoi{
  background: #777777;
  text-align: center;
  height: 26px;
  width: 26px;
  color: $white;
  border-radius: 5px;
  cursor: pointer;
  span{
    font-size: 14px;
    color: $white;
    line-height: 26px;
    vertical-align: middle;
  }
}
