.actions-bar {
  margin: 5px 10px;
}

.apartmentTabs{

}

.apartmentTab{
  display: inline-block;
  padding: 10px;
  border-radius: 3px 3px 0 0;
  border: 1px solid $light-gray;
  border-bottom: none;
  cursor: pointer;
  background: $light-gray;
  margin-right: 3px;
}

.selectedTab{
  border: 1px solid darken($light-gray, 20);
  border-bottom: none;
  background: $white;
  position: relative;
}

.withCancel{
  h4{
    display: inline-block;
    width: 50%;
  }
}

.selectedTab:after {
  position: absolute;
  content: "";
  width: 100%;
  bottom: -1px;
  left: 0;
  border-bottom: 1px solid $white;
  z-index: 5;
}

.selectWeekdays{
  padding-top: 15px;
}

.selectedRes{
  border: 1px solid darken($light-gray, 20);
  padding: 20px;
  border-radius: 0px 5px 5px 5px;
}

.apartment-wrapper {
  margin: 5px 10px;
  background-color: #d5d5d5;
  border-radius: 3px;
  border: 1px solid $light-blue;

  .apartment-filters {
    border-bottom: 1px solid $light-blue;

    .row{
      padding: 5px 0;
    }

    .btn-white{
      background-color:$white;
      color: $medium-blue;
    }

    .btn-group-justified{
      margin:3px;
    }

    .search-filter input {
      width: 100%;
      height: 35px;
      padding: 0 3px;
    }
  }
  .apartment-grid{
    height:auto;
    background-color:#FFF;
    .row{
      padding: 10px 15px;
      .playcare{
        text-align: center;
      }
    }
    .row:hover{
      background-color: #fff;
      transition: all .2s ease-in-out;
      box-shadow: 0 0 20px rgba(0, 0, 0, 0.2);
      border: 1px solid #3aafa9;
    }
  }
}
.form-component-wrapper.has-error{
  border: 1px solid #a94442;
  box-shadow: 0 0 10px #a94442;
}
.form-component-wrapper {
  border: 1px solid #AFAFAF;
  padding: 18px 0 12px 0;
  margin: 10px 0;
  border-radius: 3px;
  position:relative;
  label{
    position:absolute;
    top:0;
    left:0;
    color:#3aafa9;
  }
  input{
    position:relative;
    width:100%;
    height:24px;
    border:none;
  }
  input:focus {
    outline: 0;
  }
}

.market-container {
  margin: 5px 10px;
  background-color: #d5d5d5;
  border-radius: 3px;
  border: 1px solid #3aafa9;
}

.market-row:hover {
  background-color: #fff;
  transition: all .2s ease-in-out;
  box-shadow: 0 0 20px rgba(0, 0, 0, 0.2);
  border: 1px solid #3aafa9
}
.market-row {
  display: flex;
  width: 100%;
  padding: 10px;
  margin: 0;
  align-items: center;
}

.launch-date span {
  padding-left:5px;
  font-weight: bold;
}
.add-bundles-container .help-block {
  color: #d9534f;
}
