.specialPaymentRow{
  display: flex;
  width: 100%;
  padding:10px;
  margin:0;
  align-items: center;
  .lineItemDescription{
    overflow-y: hidden;
    white-space: nowrap;
    overflow-x: scroll;
    padding-top:16px;
  }
  .lineItemDescription::-webkit-scrollbar {
    width: 0px;  /* remove scrollbar space */
    background: transparent;  /* optional: just make scrollbar invisible */
  }

  .lineItemDescription::-webkit-scrollbar {
    width: 0px;  /* remove scrollbar space */
    background: transparent;  /* optional: just make scrollbar invisible */
  }
  .paymentThing{
    margin-top: 40px;
    border-radius: 3px;
  }
  a:hover {
    color: #3aafa9;
    cursor: pointer;
  }
}
.specialPaymentRow:hover {
  background-color:#fff;
  transition: all .2s ease-in-out;
  box-shadow: 0 0 20px rgba(0, 0, 0, 0.2);
  border: 1px solid #3aafa9;
}
.lineItemsRow{
  //background-color: $light-gray;
  height: 50px;
  margin:0;
  align-items: center;
  display:flex;
  padding: 5px;
  background-color: #fff;
  .lineItemDescription{
    display:flex;
    align-items: center;
  }
}

.user-list {
  &-container {
    margin: 10px;
    background-color: white;
    height: 100%;
  }
  &-header {
    margin-bottom: 20px;
    &:hover {
      background-color: transparent !important;
      box-shadow: none !important;

      .user-list-item {
        background-color: transparent !important;
        box-shadow: none !important;
        border: none !important;
      }
    }

    .fa-exclamation-circle {
      color: red;
      height: 40px;
      line-height: 40px;
      font-size: 20px;
    }
  }
  &-item {
    display: flex;
    align-items: center;
    min-height: 60px;
    padding: 10px;
    border-radius: 3px;
    &:hover {
      background-color: $white;
      box-shadow: 0 0 20px rgba(0, 0, 0, .2);
      border: 1px solid $medium-blue;
      transition: all .2s ease-in-out;
    }

    &-content {
      display: flex;
      padding: 0;

      &.row-amount {
        font-weight: 500;
        color: $medium-blue;
        font-size: 16px;
      }

      &.username-container {
        flex-direction: column;
      }

      &.title {
        font-family: 'Nunito';
        font-size: 14px;
        text-transform: uppercase;
        letter-spacing: .5px;
        font-weight: 500;
        color: $dark-brown;
        padding: 0;
        height: 40px;
        line-height: 40px;
        border-bottom: 1px solid $medium-blue;
      }

      &.time-requested {
        display: flex;
        flex-flow: column wrap;
      }
      .per-item {

      }
      .btn-recurring {
        display: inline-flex;
        margin-left: 5px;
        border: 1px solid $light-blue;
        height: 20px;
        width: 20px;
        align-items: center;
        justify-content: center;
        border-radius: 50%;
        font-size: 10px;
        font-weight: 500;

        &:hover {
          background-color: $light-blue;
          color: white;
          cursor: pointer;
        }
      }
    }
  }

  &-name {
    font-weight: 500;
    font-size: 16px;
    color: $black;
  }

  &-notes {
    margin: 0 20px 0 0;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    font-style: italic;
  }

  &-details-btn {

  }


}

.user-list-item.stripecharge-sub-item:hover {
  border: none !important;
}

.dashboard-tab-item {
  .user-list-item:hover {
    border: none !important;
  }
}

.payment-tabs {
  margin:5px;
  border-bottom: 1px solid #3aafa9;
  li a:hover {
    border: 1px solid #3aafa9;
    cursor: pointer;
    color: #3aafa9;
  }
  button{
    margin: 0 5px;
  }
  .filter-btn {
    background-color: #3aafa9;
    color: #fff;
  }
  .filter-btn:hover{
    background-color: #36a09b;
    border: solid 1px #2a8e89;
  }
  .btn:focus {
    outline: 0;
  }
}


.payment-wrapper {
  margin: 5px 10px;
  background-color: #d5d5d5;
  border-radius: 3px;
  border: 1px solid #3aafa9;
}

.tableRecords{
  padding: 0 5px;
}

.progress-container {
  position:relative;
  width: 80%;
  margin:auto;
  height: 120px;
}

body.locked{
  pointer-events: none;
}
