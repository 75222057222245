@import "modules/variables";

@import "vendor/bootstrap";

@import "modules/utilities";

@import "partials/admin-calendar";
@import "partials/admin-settings";
@import "partials/admin-dashboard";
@import "partials/appointment-detail";
@import "partials/buttons";
@import "partials/calendar-legend";
@import "partials/cards";
@import "partials/clientlist";
@import "partials/client-wrapper";
@import "partials/client-settings";
@import "partials/comboboxfield";
@import "partials/datepicker";
@import "partials/dropdownlistfield";
@import "partials/errors";
@import "partials/forms";
@import "partials/general";
@import "partials/links";
@import "partials/login";
@import "partials/messages";
@import "partials/modals";
@import "partials/multiselectfield";
@import "partials/nav";
@import "partials/pagination";
@import "partials/pet-cards";
@import "partials/pet-rows";
@import "partials/scheduler";
@import "partials/tables";
@import "partials/typography";
@import "partials/overlay";
@import "partials/zones";
@import "partials/scheduleAppointment";
@import "partials/media-queries";
@import "partials/user-list";
@import "partials/flags";
@import "partials/consideration-box";
@import "partials/stripe";
@import "partials/stripelist";
@import "partials/apartments";
@import "partials/market";
@import "partials/prices";
@import "partials/vacations";
@import "partials/promo";
@import "partials/partners";
@import "partials/referrals";
@import "partials/admin-table-gray";
@import "partials/vet";

$fa-font-path: "../../font-awesome/fonts";
@import "vendor/font-awesome/scss/font-awesome";

$rw-font-path: "../../font-awesome/fonts";
$rw-img-path: "../../react-widgets/img" !default;
@import "vendor/react-widgets/scss/react-widgets";

@import "vendor/rc-tooltip/tooltips";

