.partner-form-container{
  margin:auto;
}
.partner-actions{
  padding: 1em;
  .btn.btn-primary{
    margin: 5px;
  }
}
.partner-table{
  margin-top: 1em;
  background-color: #d5d5d5;
  border: 1px solid #3aafa9;
  padding: 1.25em;
  border-radius: 3px;
  .table-headers{
    border-bottom: 1px solid #3aafa9;
    margin: .25em;
    .header{
      text-align: center;
      padding-bottom:1em;
      color: #3E4649;
      letter-spacing: .5px;
      text-transform: uppercase;
    }
  }
  .table-row{
    display: flex;
    width: 100%;
    padding: 10px;
    margin: 0;
    align-items: center;
    .cell{
      text-align: center;
    }
  }
  .table-row:hover{
    background-color: #fff;
    transition: all .2s ease-in-out;
    box-shadow: 0 0 20px rgba(0, 0, 0, 0.2);
    border: 1px solid #3aafa9;
  }
}
