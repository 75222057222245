// Form Wrapper
.form-container {
  position: relative;
  width: 80%;
}

.recur-message-wrap{
  position: relative;
  .recur-message{
    position: absolute;
    top: -17px;
    left: 0;
  }
}

@media (max-width: 1000px){
  .form-container{
    width: 100%;
  }
  .recur-message-wrap{
    position: inherit;
    .recur-message{
      position: inherit;
      top: 0;
      left: 0;
    }
  }
}

.col--form-column-component {
  margin-bottom: 10px;
  min-height: 56px;
  display: flex;
  align-items: flex-end;
  justify-content: center;
  &.checkbox-field {
    align-items: center;
    justify-content: flex-start;
  }
}

// Form Controls
.form-control{
  box-shadow: none;
  -webkit-box-shadow: none;
}

.form-height {
  height: 24px;
  margin-top: 3px;
  padding: 0px;
}

// Form Labels
.form-label {
  height: 0px;
  min-height: 4px;
  font-size: 12px;
  display: block;
  margin: 0px 13px 0px 13px;
  transition: height 0.1s, padding 0.1s;
  color: $dark-blue;
  font-weight: 600;
  text-transform: uppercase;
  letter-spacing: .5px;
  margin: 3px 10px 5px;
  visibility: hidden;
}

.ta-label {
  min-height: 12px;
}

.form-label-not-empty {
  @extend .form-label;
  visibility: visible;
  height: 12px;
  margin-left: 0px;
  margin-bottom: 6px;
}

.rw-widget {
  width: 90%;
  padding-left: 0px;
}

.rw-multiselect {
  padding-left: 0px;
}

.rw-widget-input {
  width: 100%;
}

.form-sub-section {
  .hr {
    margin-top: 8px;
    margin-bottom: 14px;
  }
}

.form-sub-section h4 {
  font-weight: 400;
}

// .rw-widget-picker {
//   border-width: 0px !important;
// }

.widgetThing {
  border-width: 0px !important;
}

// Dropdowns
.dropdown-form{
  border: 1px solid $light-brown;
  color: $medium-brown;
  width: 100%;
  float: left;
  background-color: $white;
  text-align: -webkit-left;
  padding: 9.5px 17px;
  border-radius: 2px;
  .fa.fa-chevron-down{
    float: right;
    color: $medium-brown;
    position: relative;
    top: 2px;
    font-size: 12px;
  }
}

li.dropdown{
  padding: 0px;
  a.dropdown-toggle{
    cursor: pointer;
  }
}

li.dropdown.open{
  a.dropdown-toggle{
    background-color: $white;
    &:hover, &:focus, &:active{
      background-color: $white;
    }
  }
}

.dropdown-menu {
  background-color: $white;
  box-shadow: none;
  border-radius: 2px !important;
  border: 1px solid $light-brown;
  padding: 0px;
  right: 15px !important;
  top: 60px;
  box-shadow: 2px 2px 2px $medium-brown;
  ul{
    list-style-type: none;
    padding: 0px;
    li{
      padding: 15px;
      border-bottom: 1px solid $light-brown;
      a{
        font-family: 'Nunito';
        color: $medium-brown;
        font-size: 14px;
        padding: 0px !important;
      }
      span.dropdown-icon{
        color: $medium-brown;
        padding-right: 10px;
        transition: .5s all;
      }
    }
    &:hover{
      cursor: pointer;
      a{
        color: $medium-blue;
      }
      span.dropdown-icon{
        color: $medium-blue;
      }
    }
  }
}

.chevron {
  color: $light-brown;
  position: absolute;
  right: 25px;
  top: 34px;
}

// Selects
select.select, select.select:focus {
  bottom: 0;
  border-radius: 2px;
  background: $white;
  width: 100%;
  padding-left: 15px;
  margin-right: 20px;
  border: 1px solid $light-brown;
  height: 37px;
  color: $medium-brown;
  font-size: 11pt;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  box-shadow: none;
}

.select-not-empty {
  //composes: select;
  color: initial !important;
}

// Inputs
.input-wrapper--form-control-component,
.input-wrapper--form-control-wrapper-component {
  // border: 1px solid $light-brown;
  border-radius: 2px;
  height: 100%;
  min-height: 55px;
  padding: 3px 0px 0px 0px;
  &:hover {
    // border-color: $medium-blue;
  }
}

.input-wrapper--form-column-component {
  align-self: flex-start;
  width: 100%;
  &:hover {
    // box-shadow: 0 0 10px rgba(0, 0, 0, .2);
    cursor: pointer;
  }
}

.input-wrapper {
  align-self: flex-end;
  width: 100%;
  border: 1px solid $light-brown;
  border-radius: 2px;
  height: 100%;
  padding: 3px 13px 6px 13px;
}

.input-field{
  border-radius: 1px;
  [placeholder] {
    font-family: $light-font;
    color: $medium-brown;
  }
  i.fa{
    color: $medium-blue;
    position: absolute;
    z-index: 0;
  }
}

input {
  border: #ccc 1px solid;
  box-shadow: none;
  position: relative;
  bottom: 0;
  color: $black-blue;
  &.form-control {
    padding: 0px 10px !important;
  }
  @include placeholder {
    font-family: $light-font !important;
    color: $medium-brown !important;
  }
}

input.input-error {
  color: $alert-dark-red !important;
  @include placeholder{
    color: $alert-dark-red !important;
  }
}

input.input, input.input:focus, input.input-error, input.input-error:focus {
  border: #ccc 1px solid;
  box-shadow: none;
  position: relative;
  bottom: 0;
  height: 38px;
}

// Textareas
textarea.form-control{
  padding: 3px 10px 5px;
  @include placeholder {
    color: $medium-brown;
  }
}

textarea.textarea {
  border: #ccc 1px solid;
  border-radius: 2px;
  height: 38px;
  box-shadow: none;
  color: $black;
  &:focus {
    outline: none;
    border: none;
    box-shadow: none;
    -webkit-box-shadow: none;
  }
}

.input-wrapper--form-control-wrapper-component:focus{
  // outline: 5px auto $medium-blue !important;
}

.btn:focus {
   outline: 5px auto $medium-blue;
   outline-offset: -2px;
}

.field-focus {
  // outline: 5px auto $medium-blue;
}

// The following two styles are for form components with dropdowns (like DateTimeField, DropdownListField)
// where we have to apply outline to a faux container to prevent outline from wrapping dropdowns too.
.field-focus-wrapper {
  background-color: transparent;
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
}

.focus-fix {
  position: relative;
}

.form-infobox {
  margin: 20px 0 0 0;
  box-sizing: border-box !important;
  padding: 10px 15px;
  background-color: $medium-brown;
  border-radius: 3px;
  color: white;

  h4 {
    color: white;
    margin-top: 0;
    font-weight: 500;
    letter-spacing: .5px;
  }

  &.appt-summary {
    margin-top: 20px;
    margin-bottom: 20px;
    color: $gray;
    background-color: $gray-lighter;
    h4 { color: $gray; }
  }
}

.form-container p {
  color: $black;
}

.rw-widget.rw-state-disabled,
.rw-widget.rw-state-disabled:hover,
.rw-widget.rw-state-disabled:active {
  background-color: $white !important;
}

.rw-multiselect {
  padding-left: 0px !important;
  .rw-widget {
    padding-left: 0px;
  }
}

.rw-input[disabled] {
  background-color: $white !important;
}

// Simple CheckboxField
.simple-checkbox-field {
  .simple-checkbox-input {
    width: 18px;
    position: relative;
    margin: 10px 10px 0 10px;
    display: inline-block;
    label {
      width: 18px;
      height: 18px;
      cursor: pointer;
      position: absolute;
      top: 0;
      left: 0;
      background-color: $white;
      border-radius: 2px;
      border: 1px solid $medium-brown;
      &:after {
        content: '';
        width: 9px;
        height: 5px;
        position: absolute;
        top: 5px;
        left: 4px;
        border: 2px solid $dark-brown;
        border-top: none;
        border-right: none;
        background: transparent;
        opacity: 0;
        transform: rotate(-45deg);
      }
      &:hover::after {
        opacity: 0.3;
      }
    }
    input[type=checkbox] {
      visibility: hidden;
      &:checked + label:after {
        opacity: 1;
      }
    }
  }
  .simple-checkbox-label{
    cursor: pointer;
    @extend .noselect;
  }
}

// ColorField
.color-picker{
  display: inline-block;
  margin: 0 10px;
  .picker-wrapper{
    display: flex;
    flex-direction: row;
    align-items: center;
    label{
      margin-bottom: 0;
      margin-right: 10px;
      font-weight: normal;
      font-size: 15px;
    }
  }
  .swatch-color{
    width: 36px;
    height: 14px;
    border-radius: 2px;
  }
  .swatch{
    padding: 5px;
    background: #fff;
    border-radius: 1px;
    box-shadow: 0 0 0 1px rgba(0,0,0,.1);
    display: inline-block;
    cursor: pointer;
  }
  .swatch-popover{
    position: absolute;
    z-index: 2;
  }
  .swatch-cover{
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
  }
}

/*
<div className="toggle-box">
  <label for="box-1">Scoop Litter Box</label>
  <input type="checkbox" id="box-1" value="1" checked="checked" />
</div>
*/

.toggle-box-container {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  margin-bottom: 10px;
  &.small-buttons {
    .toggle-box {
      width: 100px;
      height: 50px;
    }
  }
  &-title {
    line-height: 55px;
    padding-right: 10px;
  }
}

.admin-apptdetails {
  .input-wrapper--form-column-component:hover {
    box-shadow: none;
  }
}

.toggle-box {
  font-weight: normal;
  width: 150px;
  height: 80px;
  white-space: normal;
  margin: 10px;
  display: flex;
  box-sizing: border-box;
  flex-direction: column;
  justify-content: center;

  padding: 10px 10px !important;
  border-radius: 3px;

  &:hover {
    box-shadow: 0 0 10px rgba(0, 0, 0, .2);
  }

  label {
    font-weight: 200;
    margin: 0;
    cursor: pointer;
  }

  input {
    display: none;
  }

  .showInput{
    display: inline;
  }

  &.checked {
    @include btn-primary();
    background-color:lighten($light-blue, 17%);
    border:1px solid $light-blue;
    color:$medium-blue;
    margin: 10px;
  }

  &.disabled {
    background-color:$alert-light-red;
    border: 1px solid $gray-base;
    color: $gray;
  }

  &.isAdmin {
    &:hover { box-shadow: none !important; }
  }
  &.weekdays-field {
    width: 60px;
    height: 40px;
    margin: 7.5px 10px 7.5px 0;
    padding: 0;
  }
}
textarea {
  padding: 15px;
  border-color: $light-brown;
}
textarea.text-large {
  width: 100%;
  height: 150px;
}

.checkbox {
  width: 200px;
}
