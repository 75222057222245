.stripecharge {
  &-list-item {
    border: 1px solid transparent;

    &:hover {
      cursor: pointer;
    }
    &.expanded {
      background-color: white;
      border-radius: 3px 3px 0 0;
      border: 1px solid $dark-brown;
      border-bottom: 0px;
    }
    .fa-chevron-down,
    .fa-chevron-up {
      display: inline-block;
      margin-right: 10px;
      color: $dark-brown;
      &:hover {
        color: $medium-blue;
      }
    }
      .fa-chevron-up {
        color: $alert-dark-blue;
      }
  }
  &-sub-item {
    background-color: #E5E5E5;
    border-radius: 0px;
    min-height: 40px;
    border-left: 1px solid $dark-brown;
    border-right: 1px solid $dark-brown;

    a { color: $dark-brown; }

    &:hover {
      background-color: $dark-brown;
      a { color: white; }
    }

    &:last-child {
      border-bottom: 1px solid $dark-brown;
      margin-bottom: 10px;
      border-radius: 0 0 3px 3px;
    }

    .user-list-item-content {
      a:hover .fa-long-arrow-right {
          margin-left: 5px;
          transition: .4s margin-left ease-in-out;
      }
    }
  }
}

@media only screen and (max-width: 768px) {
  .stripecharge-sub-item .row-amount {
    display: flex;
    justify-content: flex-end;
    align-content: flex-end;
  }

  .stripecharge-sub-item:not(:first-child) {
    min-height: auto !important;
  }
}
