// Overlay
.overlay {
  height: 100%;
  width: 100%;
  position: fixed;
  z-index: 10000;
  left: 0;
  top: 0;
  background-color: rgba(255, 255, 255, 1);
  // background-color: #B6B3AD;
  overflow-x: hidden;
  transition: height 0.25s;
  // a {
  //   padding: 8px;
  //   text-decoration: none;
  //   font-size: 36px;
  //   color: #818181;
  //   display: block;
  //   transition: 0.3s;
  //   &:hover, &:focus {
  //     color: #f1f1f1;
  //   }
  // }
  .overlay-content-wrapper {
    width: 90%;
    min-height: 90%;
    display: flex;
    align-items: center;
    .closebtn {
      position: absolute;
      top: 20%;
      color: $white;
      right: 50px;
      font-size: 14px;
      line-height: 37px;
      width: 37px;
      height: 37px;
      border-radius: 50%;
      background-color: $light-brown;
      text-align: center;
      cursor: pointer;
      z-index: 5;
    }
  }
  .overlay-content {
    flex: 1;
    width: 100%;
    margin: 0 20px;
    text-align: center;
  }
}

@media (max-width: $medium){
  .overlay{
    .overlay-content{
      top: 10%;
      margin-top: 10px;
    }
  }
}

.user-overlay-cancel{
  cursor: pointer;
  &:hover{
    text-decoration: underline;
  }
}

@media screen and (max-height: 450px) {
  .overlay .closebtn {
    top: 20%;
    color: $white;
    right: 35px;
  }
}
