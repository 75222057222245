.consideration-box {
  border: 1px solid #FFB575;
  background-color: #fff4ea;
  padding: 20px;
  color: #FFB575;
  border-radius: 5px;
  margin: 0 auto;

  p {
    margin-bottom: 0px;
  }

  span {
    color: $alert-dark-red;
    margin-right: 5px;
  }
}
