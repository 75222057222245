a{
  transition: 0.5s all;
}

a:hover{
  text-decoration: none;
}

.remove-link{
  float: right;
  display: inline-block;
  color: $medium-blue;
  cursor: pointer;
  margin: 13px 0 0;
  span.fa{
    padding-right: 5px;
  }
  &:hover{
    color: $alert-dark-red;
  }
}

.inline-link-right{
  display: inline-block;
  float: right;
  padding: 10px 0 0;
  color: $dark-brown;
  border-bottom: 1px solid $dark-brown;
  cursor: pointer;
  transition: 0.5s all;
  a{
    text-decoration: none;
    color: $dark-brown;
    &:hover{
      color: $medium-blue;
    }
  }
  &:hover{
    border-bottom: 1px solid $medium-blue;
  }
  span.fa{
    padding-right: 5px;
  }
}

.inline-link{
  display: inline-block;
  padding: 10px 0 0;
  color: $medium-blue;
  border-bottom: 1px solid $medium-blue;
  cursor: pointer;
  @extend .noselect;
  a{
    text-decoration: none;
    color: $medium-blue;
    &:hover{
      color: $dark-blue;
    }
  }
  span.fa{
    padding-right: 5px;
  }
}
