.card-header{
  background-color: $light-brown;
  padding: 30px 80px;
  h1{
    display: inline-block;
  }
  .form-group.input-field{
    position: relative;
    width: 100%;
    border: $light-brown;
    input{
      width: 100%;
      padding: 10px 17px;
      border-radius: 2px;
    }
  }
  span.fa.fa-search{
    position: absolute;
    z-index: 1;
    top: 12px;
    right: 15px;
  }
  .search-container{
    display: flex;
    align-items: center;
    label{
      margin-right: 5px;
    }
    .search-select{
      width: 100%;
      padding: 7px 13px;
    }
  }
}

.flags-user-active-green{
  background-color: $white;
}

@media (max-width:$xtra-large){
  .card-header{
    padding: 30px;
  }
}

@media (max-width:$large){
  .card-header{
    padding: 20px;
  }
}

@media (max-width:$small){
  .card-header{
    padding: 20px 5px;
    .form-group.input-field{
      padding: 15px 0 0;
    }
    span.fa.fa-search{
      top: 26px;
    }
  }
}
