.modal-background {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background-color: $modal-background;
  opacity: 0.720807;
  z-index: 999;
}

.modal-container {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);

  z-index: 1000;

  .modal-content {
    border: 1px solid $light-brown;
    border-radius: 2px;
    box-shadow: 0px 2px 4px $black;
    .modal-header {
      border-bottom-color: $light-brown;
    }
    .modal-body {
      color: $dark-brown;
    }
    .modal-footer {
      border-top-color: $light-brown;
    }
  }
}

.modal-container-schedule {
  position: fixed;
  top: 47%;
  left: 37%;
  transform: translate(-50%, -50%);

  z-index: 1000;

  .modal-content {
    border: 1px solid $light-brown;
    border-radius: 2px;
    box-shadow: 0px 2px 4px $black;
    .modal-header {
      border-bottom-color: $light-brown;
    }
    .modal-body {
      color: $dark-brown;
    }
    .modal-footer {
      border-top-color: $light-brown;
    }
  }
}

.modal-background-enter {
  opacity: 0.01;
}

.modal-background-enter.modal-background-enter-active {
  opacity: 0.720807;
  transition: opacity 150ms linear;
}

.modal-background-leave {
  opacity: 0.720807;
}

.modal-background-leave.modal-background-leave-active {
  opacity: 0.01;
  transition: opacity 150ms linear;
}

.modal-enter {
  opacity: 0.01;
  margin-top: -20%;
}

.modal-enter.modal-enter-active {
  opacity: 1;
  margin-top: 0;
  transition: opacity 150ms ease-out, margin-top 200ms ease-out;
}

.modal-leave {
  opacity: 1;
  margin-top: 0;
}

.modal-leave.modal-leave-active {
  opacity: 0.01;
  margin-top: -20%;
  transition: opacity 150ms ease-out, margin-top 200ms ease-out;
}

@media only screen and (max-width: 768px) {
  .modal-container .modal-dialog {
    width: 90vw;
    margin: 0;
  }
}

@media only screen and (max-width: 450px) {

}
