@import url('https://fonts.googleapis.com/css?family=Nunito:200,400,600,700,800');

// Blues
$background-blue: #E5F3F1;
$light-blue: #9DD7CC;
$medium-blue: #3aafa9;
$dark-blue: #2b7a78;
$black-blue: #17252a;

// Neutrals
$white: #FFFFFF;
$light-gray: #F5F5F5;
$light-brown: #E0DDD6;
$medium-brown: #B1ADA1;
$dark-brown: #77736F;
$black: #40514E;
$shade: #F5F5F5;

$white: #FFFFFF;
$light-gray: #E2E2E2;
$light-brown: #AFAFAF;
$medium-brown: #7F8282;
$dark-brown: #3E4649;
$black: #17252A;
$shade: #E2E2E2;

// Alerts
$alert-light-green: #C8EBBF;
$alert-dark-green: #22910E;
$alert-light-blue: #9DD7CC;
$alert-dark-blue: #74B1A5;
$alert-light-orange: #F9CD9F;
$alert-dark-orange: #7F4D2A;
$alert-light-red: #F4BCB7;
$alert-dark-red: #D61515;

// Classifications
$classification-yellow: #FFD86A;
$classification-red: $alert-dark-red;

// modals
$modal-background: #333;

//Secondary Colors
$purple: #5E548E;
$coral: #F27C7C;
$green: #AFD377;
$blue: #57A8E5;
$orange: #FCB674;
$teal: #5CCCB3;
$pink: #E985C4;

//Scheduling
$completed: #FFB575;
$in-progress: #F8787A;
$approved: #AFD47B;
$pending: #FED866;
$change-requested: #AB8BE8;
$selected: #91CDC2;

// Breakpoints
$xtra-large: 1440px;
$large: 1030px;
$medium: 991px;
$small: 767px;
$xtra-small: 400px;

// Fonts
$light-font: 'Nunito';
