table.card-list{
  width: 100%;
  border-collapse: separate;
  border-spacing: 0 1em;
  padding: 20px;
  thead{
    tr{
      border-bottom: 1px solid $medium-brown;
      th{
        padding: 20px 0 10px 30px;
        font-family: 'Nunito';
        font-weight: 100;
        color: $dark-brown;
      }
    }
  }
  tbody{
    tr{
      height: 60px;
      margin: 15px 0;
      box-shadow: 0px 2px 4px $light-brown;
      border: 1px solid $light-brown;
      td{
        padding: 0 0 0 30px;
        font-family: 'Nunito';
        font-weight: 100;
        color: $black;
        font-size: 16px;
        border-top: 1px solid $light-brown;
      }
    }
  }
}
