// Cards

.clientPetCard{
  min-height: 1000px;
}

.clientPetCard .page-title {
  margin-bottom: 20px;
}

.card {
  position: relative;
  height: auto;
  background-color: $white;
  border-radius: 2px;
  padding: 20px 0px;
  margin-bottom: 20px;

  &-container {
    width: 80%;
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    margin: 0 auto;
    padding: 20px 0;

    &-item {

    }
  }

  &-calendar {
    border-radius: 2px;
    overflow: hidden;
  }

  &-focused {
    border: 2px solid $alert-light-red;
  }

  &-client-balance {
    .card-text {
      font-size: 12px;
      text-transform: uppercase;
      letter-spacing: .5px;
      font-weight: 100;
      color: #77736F;
      text-align: right;

      .credit {
        display: inline-block;
      }
      .note {
        font-size: 12px;
        text-transform: none;
        color: $medium-brown;
        margin-top: 10px;
      }
    }
    .outstanding {
      display: flex;
      align-items: center;
    }
    .outstanding .card-result-text {
      color: $alert-dark-red;
      align-self: center;
    }
    .outstanding .btn-secondary {
      display:inline-block;
      margin-left: 20px;
      margin-top: -8px;
      padding: 10px 20px;
    }
  }

  &-result-text {
    color: $medium-blue;
    font-weight: 700;
    font-size: 32px;
  }
}

.map-card {
  margin: -40px -20px -40px -20px;
  padding-bottom: 0px;
  .date-switcher {
    padding: 6px 0px 3px 0px;
  }
}

.card-content {
  padding: 50px;
}

@media only screen and (max-width: 768px) {
  .card-container {
    width: 100%;
  }
  .card-client-balance {
    .card-text,
    .card-result-text { text-align: center; }
  }
}
