html, body, div.root, div.root > div:first-child {
    color: $black-blue;
    font-family: 'Nunito';
    font-weight: 400;
}

.admin-container {
  margin: 90px 20px 0px 20px;
  border-radius: 3px;
  padding-bottom: 20px;
}

.client-container {
  margin: 100px 35px 35px 35px;
  border-radius: 3px;
  padding-bottom: 20px;
}

.title {
  padding: 0px 15px;
  font-weight: 600;
}

.page-title h1 {
  font-weight: 600;
}

.value {
  font-size: 24px;
  color: $medium-brown;
  font-weight: 400;
}

.no-title {
  display: none;
}

.hr {
  margin-top: 24px;
  margin-bottom: 0px;
  border-color: $light-brown;
}

p.red-text{
  color: $alert-dark-red;
}

.color-black { color: black; }


.item-wrapper {
  margin-bottom: 25px;
}

.item-wrapper-horizontal {
  margin-bottom: 25px;
  display: inline-block;
  padding-left: 100px;
  padding-top: 30px;
}

ul {
  &.no-decoration {
    padding-left: 0px;
    color: $black;
    list-style: none;
    font-size: 15px;
    line-height: 22px;
  }
}

// Loading spinner
.loading-spinner-bg {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 1050;
}
.loading-spinner-img {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 1051;
  @extend .noselect;
}
.spinner-active {
  overflow: hidden;
}

// Tooltips
.annotation-tooltip{
  max-width: 250px;
}

// Profile Image Dropzone
.pet-image-dropzone{
  z-index: 10;
  height: 150px;
  width: 150px;
  border-radius: 75px;
  background-size: cover;
  border: 1px solid $dark-brown;
  cursor: pointer;
  @extend .noselect;
  &:hover{
    border: 1px dashed $dark-brown;
  }
  .dropzone-contents{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height: 100%;
    width: 100%;
    border-radius: 75px;
    color: $dark-brown;
    background-color: rgba(white, .85);
    visibility: visible;
    opacity: 0;
    &:hover{
      opacity: 1;
    }
    .fa-picture-o{
      font-size: 26px;
    }
  }
}

.page-section {
  padding:20px;
  &+.page-section {
    padding-top:0;
  }
}

.section-header {
  color: $dark-brown;
  background-color: $light-brown;
  padding: 8px;
  font-size: 14px;
  text-align:center;
  margin:0;
}

.section-content {
  padding: 20px 0px;
}
