.login-bg {
  background-color: $light-blue;
  height: 100vh;
  margin: 0 auto;
  text-align: center;
  button {
    background-color: $black-blue;
    color: $white;
    width: 100%;
    max-width: 200px;
    font-size: 18px;
    border-radius: 100px;
    &:hover {
      background-color: $dark-blue;
      color: $white;
    }
  }
  form {
    max-width: 500px;
    margin: 0 auto;
    .form-group {
      .form-control {
        height: 41px;
        border-radius: 3px;
        font-size: 16px;
      }
    }
  }
  label {
    display: none;
  }

}

.login-logo{
  width: 100%;
  max-width: 400px;
  display: block;
  margin: 0 auto;
}

.login-img{
  padding: 70px 50px;
}
