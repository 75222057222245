//Custom
.rw-multiselect {
  border-width: 0 !important;
  padding: 0 10px;
  box-shadow: none !important;
  .rw-multiselect-taglist {
    font-size: 14px;
    .rw-tag-btn {
      padding-left: 4px;
    }
  }
  ul {
    li {
      padding: 1px 5px;
      color: $white;
      border-color: $medium-blue;
      background-color: $medium-blue;
      &.rw-list-option {
        background-color: transparent;
        color: $medium-brown;
        border:none !important;
        &:hover {
          color: black;
        }
      }

      &:hover {
        background-color: $dark-blue;
        border-color: $dark-blue;
        transition: all .3s ease-in-out;
      }
    }
  }
  .rw-input {
    height: 24px;
    padding: 0px;
  }
}

.multiselect-label {
  &.form-label-not-empty {
    height: 15px;
  }
  &.form-label-empty {
    margin-top: 3px;
  }
}

.flex-row.calendar-header{
  margin-bottom: 20px;
  align-items: center;
  .segmented-control{
    flex-shrink: 0;
    .btn-group{
      margin-bottom: 0;
    }
  }
  .form-group.input-field{
    max-height: 999px;
    .search-icon{
      display: flex;
      align-items: center;
      background-color: $white;
      padding: 0 10px;
      span.fa.fa-search{
        position: inherit;
      }
    }
    .rw-multiselect{
      @extend .noselect;
      width: 100%;
      padding: 0 10px;
      input{
        width: inherit;
        padding: 0;
        &:focus{
          outline: none !important;
        }
      }
      .rw-multiselect-wrapper{
        padding: 7px 0 6px 0;
        height: 100%;
        ul{
          li{
            padding-top: 0px;
            &:last-of-type{
              margin-right: 10px;
            }
          }
        }
      }
    }
  }
}
