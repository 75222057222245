.client-wrapper-btn{
  display: inline-block;
  font-family: $light-font;
  border-radius: 2px;
  padding: 10px;
  background-color: transparent;
  width: 160px;
  img{
    display: block;
    margin: 0 auto;
    padding: 0 0 8px 0;
  }
  p{
    font-family: $light-font;
    color: $medium-brown;
    font-size: 15px;
    text-align: center;
    margin: 0;
    padding: 3px 0 0;
  }
}

.purple {
  @extend .client-wrapper-btn;
  border: 1px solid $purple;
  color: $purple;
  &:hover{
    color: $purple;
    background-color: rgba($purple, 0.1);
  }
}

.green {
  @extend .client-wrapper-btn;
  border: 1px solid $green;
  color: $green;
  &:hover{
    color: $green;
    background-color: rgba($green, 0.1);
  }
}

.coral {
  @extend .client-wrapper-btn;
  border: 1px solid $coral;
  color: $coral;
  &:hover{
    color: $coral;
    background-color: rgba($coral, 0.1);
  }
}

.blue {
  @extend .client-wrapper-btn;
  border: 1px solid $blue;
  color: $blue;
  &:hover{
    color: $blue;
    background-color: rgba($blue, 0.1);
  }
}

.orange {
  @extend .client-wrapper-btn;
  border: 1px solid $orange;
  color: $orange;
  &:hover{
    color: $orange;
    background-color: rgba($orange, 0.1);
  }
}

.teal {
  @extend .client-wrapper-btn;
  border: 1px solid $teal;
  color: $teal;
  &:hover{
    color: $teal;
    background-color: rgba($teal, 0.1);
  }
}

.pink {
  @extend .client-wrapper-btn;
  border: 1px solid $pink;
  color: $pink;
  &:hover{
    color: $pink;
    background-color: rgba($pink, 0.1);
  }
}

@media (max-width: $small){
  .client-wrapper-btn{
    width: 100%;
  }
}
