// Appointment Detail
.pet-name-service{
  margin-bottom: 10px;
}

.pet-services{
  background-color: $background-blue;
  border: 1px solid $light-blue;
  margin: 10px 0;
  .servicePetName{
    padding-left: 10%;
    h4{
      line-height: 80px;
      vertical-align: middle;
      font-size: 20px;
    }
  }
}

.linkTo{
  color: $light-blue;
  font-weight: bold;
  cursor: pointer;
}

.petAddOn{
  background-color: $light-blue;
  color: $white;
  padding: 5px;
  border-radius: 3px;
  margin: 0 3px;
}
.appointment-detail-page, .schedule-appointment-page {
  span.fa{
    color: $medium-brown;
  }
  span.fa-home{
    &.yellow{
      color: $classification-yellow;
    }
    &.red{
      color: $classification-red;
    }
  }
  div.inline-zone-badge{
    display: inline-block;
    vertical-align: text-bottom;
    line-height: 20px;
  }
  .price-override{
    display: flex;
    align-items: center;
    padding: 6px 0;
    min-height: 52px;
    flex-wrap: wrap;
    .simple-checkbox-input{
      margin-top: 0;
      margin-left: 0;
    }
    div{
      margin-right: 10px;
      margin-bottom: 4px;
      &.override-price{
        margin-left: 0px;
      }
    }
  }

  .simple-checkbox-label{
    color: $black;
  }

  .message {
    color: $dark-brown;
    margin: 0 auto;
    padding: 15px;
    text-align: center;

    &-charges {
      color: $dark-brown;
      margin: 20px auto;
      display: flex;
      flex-direction: column;
    }
    &-charge-item {
      padding: 15px;
      flex-grow: 1;
      display: flex;
      border: 1px solid transparent;

      &>div {
        padding: 0;
        align-items: center;
      }
      &:nth-child(odd) {
        background-color: $light-gray;
      }

      &:hover {
        transition: all .3s ease-in-out;
        background-color: $white;
        border-color: $alert-light-blue;
        box-shadow: 0 0 20px rgba(0, 0, 0, .2);
      }
      &-description {
        font-weight: 500;
        line-height: 25px;
      }
      &-amount {
        font-size: 18px;
        font-weight: 700;
        display: inline-flex;
        margin: 0px;
        color: $alert-light-red;
        &.refund {
          color: $medium-blue;
        }
      }
      &-status {
        display: inline-flex;
        &-content {
          font-style: italic;
          min-width: 130px;
        }
      }
      &-receipt {
        color: $medium-brown;
        .dateCreated {
          margin-top:-7px;
          strong {
            font-size: 14px;
            display: block;
          }
          font-size: 10px;
          text-transform: uppercase;
        }
      }
    }
  }
}

.appointment-detail-contactname {
  a { line-height: 34px; height: 34px; }
}

.appt-recurring-options {
  .toggle-box-container {
    justify-content: flex-start;
  }
}

.appt-recurring-container .input-wrapper--form-column-component:hover {
  box-shadow: none;
}

.appt-recurring-container .input-wrapper--form-column-component
.toggle-box-container {
  margin: 0;
  background-color: $light-gray;
  border-radius: 3px;
}


.appointment-detail-lighttext {
  color: $gray-light;
  padding-top: 10px;
}

// appointment line items

.clientdash-apptlist {
  &-header {
    border-bottom: 1px solid $medium-blue;

    .col-title {
      font-size: 16px;
      color: black;
      height: 39px;
      &:hover {
        border-bottom: 4px solid $medium-blue;
        transition: border-bottom .3s ease-in-out;
      }
    }
    padding: 0px;
    margin: 20px auto;
  }
}

.appointment-line-item {
  &.container {
    margin: 0 auto;
    padding: 0;
    border: 1px solid white;
    border-radius: 3px;

    &:hover {
      border-color: $light-blue;
      box-shadow: 0 0 10px rgba(0, 0, 0, .1);
      transition: all .3s ease-in-out;
      cursor: pointer;

      .col-date {
        background-color: $medium-blue;
        transition: background-color .3s ease-in-out;
        color: white;
      }

      .col-appt-type {
        color: $medium-blue;
      }
    }
  }
  .col-content {
    display: inline;
    line-height: 40px;

    &.col-date {
      text-transform: uppercase;
      font-weight: 500;
    }

    &.col-appt-type {
      text-transform: uppercase;
      letter-spacing: 1px;
      font-weight: 500;
      color: black;
    }

    &.col-time {
      letter-spacing: 1px;
      font-weight: 500;
    }

    &.col-viewdetails {
      &:hover { color: $medium-blue; }
      color: black;
    }
  }

  margin-bottom: 5px !important;
}






.appt-status {
  margin-top: 0px;
  margin-bottom: 15px;
  padding: 8px;
  min-height: 30px;
  color: $dark-brown;
  border-width: 1px 0px !important;
  border-style: solid;
  p {
    margin: 0px;
    @extend .noselect;
  }
  &.rejected {
    background-color: $alert-light-red;
    border-color: $alert-dark-red;
  }
  &.canceled {
    background-color: $alert-light-orange;
    border-color: $alert-dark-orange;
  }
}

.appointment-price-wrapper {
  margin: 30px 0;
  @extend .noselect;
  p {
    margin: 10px 0;
  }
  .appointment-price-banner {
    background-color: $light-blue;
    height: 60px;
    p {
      text-transform: uppercase;
      color: $white;
      font-weight: 500;
      letter-spacing: 0.5px;
      height: 60px;
      line-height: 60px;

      margin: 0;
    }

    .price-banner-content {
      text-transform: uppercase;
      color: $white;
      letter-spacing: 0.5px;
      height: 60px;
      line-height: 60px;
      font-size: 16px;
      margin: 0;

      .money {
        font-size: 24px;
        line-height: 60px;
        font-weight: 700;
        margin-left: 20px;
        color: $white;
      }
    }

    .money {
      color: $dark-blue;
      font-weight: bold;
    }
  }
  .appointment-price-subtext {
    p {
      color: $medium-brown;
    }
  }
}

.appointment-image-wrapper {
  display: flex;
  flex-wrap: wrap;
  .appointment-image-box{
    position: relative;
    .delete-image{
      position: absolute;
      top: 3px;
      right: 25px;
      background-color: $white;
      height: 24px;
      width: 24px;
      display: flex;
      justify-content: center;
      border-radius: 50%;
      cursor: pointer;
      @extend .noselect;
      .fa-times-circle{
        color: $black;
        font-size: 22px;
        line-height: 24px;
        transition: .5s color;
        &:hover{
          color: $alert-dark-red;
        }
      }
      .fa-undo{
        color: $black;
        font-size: 18px;
        line-height: 24px;
        transition: .5s color;
        &:hover{
          color: $dark-blue;
        }
      }
    }
    img {
      height: 160px;
      width: 160px;
      margin: 0 20px 20px 0;
      cursor: pointer;
      transition: .5s opacity;
      &.marked-for-deletion{
        opacity: 0.25;
      }
    }
  }
}

.relatedAppointments {
  padding-top: 20px !important;
}

.relatedAppointment {
  display: flex;
  flex-flow: row wrap;
  border-bottom: 1px solid $gray-lighter;
  padding: 10px;
  div { padding: 0; }
  &.header {
    font-weight: bold !important;
    color: $medium-blue;
    &:hover {
      background-color: transparent;
      cursor: default;
    }
  }
  .teamMember a {
    font-weight: bold !important;
    &:hover { color: $medium-blue; }
  }
  &:hover {
    background-color: $gray-lighter;
    cursor: pointer;
  }
}

.overlay-pet-image {
  max-width: 80vw;
  max-height: 80vh;
}

.editRS-weekdays-block {
  .input-wrapper--form-column-component:hover {
    box-shadow: none !important;
  }
  .toggle-box-container {
    margin-bottom: 0;
    background-color: $gray-lighter;

  }
}

.clientNotes {
  color: $gray;
  background-color: $gray-lighter;
  border-left: 2px solid $medium-blue;
  padding: 10px 20px;
  margin: 20px 0;
}

.note-changes,
.edit-actionbtn-container {
  margin-top: 20px;
}

.color-green {
  color: $medium-blue;
}

@media only screen and (max-width: 768px) {
  .appointment-detail-page {
    .message,
    .message-charges {
      width: 100%;
      padding: 0px 5px;
    }
  }

  .message-charge-item {
    flex-wrap: wrap;
    &-receipt .dateCreated {
      margin-top: 0px !important;
    }
  }

  .appointment-line-item {
    .col-content {
      line-height: 30px;
    }
  }

  .appointment-line-item {
    &.container {
      &:hover {
        .col-date {
          line-height: 60px;
        }
      }
    }
  }


}

@media only screen and (min-width: 450px) and (max-width: 768px) {

}

@media only screen and (min-width: 1200px) {
  .container {
    width: 100%;
    max-width: 1160px;
    padding: 0;
  }
}

@media only screen and (max-width: 1200px) {
  .container {
    width: 100%;
    max-width: 1160px;
    padding: 0 15px;
  }

  .toggle-box-container-title {
    width: 100%;
    text-align: center;
  }
}


@media only screen and (max-width: 450px) {
  .appointment-detail-page {
    .message,
    .message-charges {
      width: 100%;
      padding: 0px 5px;
    }
    .message-charge-item {
      min-width: 100%;
    }
  }
  .price-override .override-price {
    width: 100%;
    margin-right: 0 !important;
    .input-wrapper--form-control-wrapper-component {
      margin-right: 0;
    }
  }

  .clientdash-apptlist-header .col-title,
  .clientdash-apptlist .col-content {
    padding: 0 !important;
  }

  .clientdash-apptlist-header .col-title {
    &.col-date { text-align: center; }
    &.col-appt-type { text-align: right; }
    &.col-status { padding-left: 10px !important; color: #B6B3AD; }
  }

  .appointment-line-item {
    .col-content {
      line-height: 30px;
      letter-spacing: 0 !important;
      &.col-date {
        line-height: 60px;
        background-color: $medium-blue;
        color: white;
        text-align: center;
      }
      &.col-appt-type {
        text-align: right !important;
      }
      &.col-status {
        text-align: left;
        padding-left: 10px !important;
      }
    }

    border-bottom: 1px solid $medium-blue !important;
  }

  .appt-recurring-options .toggle-box-container {
    justify-content: center;
  }

}


.padding-left0 {
  padding-left: 0;
}
