/**
 * The CSS shown here will not be introduced in the Quickstart guide, but shows
 * how you can use CSS to style your Element's container.
 */
.StripeElement {
  background-color: white;
  padding: 8px 12px;
  border-radius: 4px;
  border: 1px solid transparent;
  box-shadow: 0 1px 3px 0 #e6ebf1;
  -webkit-transition: box-shadow 150ms ease;
  transition: box-shadow 150ms ease;
  margin-bottom: 10px;
}

.StripeElement--focus {
  box-shadow: 0 1px 3px 0 #cfd7df;
}

.StripeElement--invalid {
  border-color: #fa755a;
}

.StripeElement--webkit-autofill {
  background-color: #fefde5 !important;
}

#payment-form {
  margin-top: 20px;
  padding: 10px;
  box-sizing: border-box;
  border: 1px solid $light-brown;
  background-color: $light-brown;
  border-radius: 3px;

  label {
    font-size: 12px;
    text-transform: uppercase;
    letter-spacing: .5px;
    font-weight: 100;
    color: $dark-brown;
  }

  &:hover {
    border-color: $medium-brown;
    transition: all .3s ease-in-out;
    box-shadow: 0 0 20px rgba(0, 0, 0, .2);
  }

  button {
    font-family: 'Nunito';
    font-weight: 100;
    outline: none;
    transition: .3s all ease-in-out;
    border-radius: 3px;
    background: #9DD7CC;
    cursor: pointer;
    border: 0px;
    padding: 5px 15px;
    color: #FFFFFF;

    &:hover {
      background: #85C8C2;
    }
  }
}

//credit card current user/client info
.cc-current-wrapper {
  padding: 0 15px;

  .col-md-6,
  .col-md-2 {
    height: 39px;
    line-height: 39px;
  }

  .cc-field {
    background-color: $light-brown;
    font-size: 12px;
    text-transform: uppercase;
    letter-spacing: .5px;
    font-weight: 100;
    color: $dark-brown;
    border-radius: 3px;
    padding:0 10px;

    &-date, &-last4 {
      font-weight: 500;
      color: $black;
    }
  }

  .button-column {
    padding:0;

    button {
      font-family: 'Nunito';
      font-weight: 100;
      outline: none;
      width: 100%;
      transition: .3s all ease-in-out;
      border-radius: 3px;
      background: #9DD7CC;
      cursor: pointer;
      border: 0px;
      color: #FFFFFF;
      padding: 0;
    }
  }
}

.cc-cancel-btn {
  margin-left: 10px;
}
