.client-scheduler {
  .calendar-month-wrapper {
    padding-left: 0;
    padding-right: 0;
    margin-left: 0;
    margin-right: 0;
    padding: 0;
    margin: 0;
    .day-wrapper {
      border-width: 0;
      border-left-width: 1px;
      border-color: $light-brown;
      border-style: solid;
      &:last-child {
        border-right-width: 1px;
      }
      h4 {
        color: $light-blue;
      }
    }
  }
  .date-switcher {
    background-color: $light-blue;
    padding: 6px 0;
    border: 1px solid $light-brown;
    h1 {
      color: $white;
    }
    .pagination {
      color: $white;
      .btn.pagination {
        border-color: $white;
        margin: 8px 0;
      }
    }
  }
  .day-block {
    &:hover {
      background-color: $background-blue;
    }
    .pet-card-xtra-condensed {
      background-color: $light-gray;
      border-color: $medium-brown;
      cursor: default;
      p {
        color: $dark-brown;
      }
    }
    &.disabled-day {
      background-color: $light-gray;
    }
    &.selected-day {
      background-color: $background-blue;
      .client-new-appointment {
        background: $light-blue;
        border: 1px solid $medium-blue;
        border-radius: 2px;
        margin: 4px 6px;
        cursor: pointer;
        @extend .noselect;
        .client-new-appointment-segment {
          border-bottom: 1px solid $medium-blue;
          display: flex;
          flex-direction: row;
          justify-content: space-between;
          color: $black;
          padding: 5px 10px;
          font-size: 12px;
          &:last-child {
            border-bottom: none;
          }
          .pet-names {
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
          }
        }
      }
    }
    .existing-appts-day-header {
      background-color: $light-gray;
      text-align: center;
      font-size: 11px;
      padding: 4px;
      border-width: 1px 0;
      border-style: solid;
      border-color: $light-brown;
      cursor: default;
      @extend .noselect;
    }
  }
}

.highlight-header {
  background: $medium-blue;
  height:60px;
  .input-wrapper--form-control-wrapper-component {
    border: none;
    padding: 3px 0 0 0;
    label {
      color: $black;
    }
  }
}

.schedule-new-appointment-card {
  .pet-row {
    box-shadow: none;
  }
  .right-header-actions {
    display: flex;
    align-items: center;
    .remove-appointment-button {
      display: flex;
      justify-content: center;
      align-items: center;
      color: $dark-blue;
      width: 56px;
      height: 60px;
      &:hover {
        background-color: $light-blue;
      }
    }
  }
  .inline-link {
    @extend .text-center;
    display: block;
    border-bottom: none;
    padding: 26px 0 0 0;
    a {
      border-bottom: 1px solid $dark-brown;
      color: $dark-brown;
      &:hover {
        border-color: $dark-blue;
        color: $dark-blue;
      }
    }
  }
  .highlight-header {
    .rw-dropdownlist {
      &:hover {
        background-color: $medium-blue;
      }
      &.rw-widget {
        border: none;
        border-radius: 0;
        height: 56px;
        .rw-input {
          border-radius: 0;
          h1 {
            color: $white;
          }
        }
        &.rw-open {
          .rw-input {
          }
        }
      }
      .rw-dropdownlist-picker {
        background-color: $medium-blue;
        .rw-i-caret-down {
          color: $dark-blue;
        }
      }
      .rw-input {
        height: 100%;
        padding-top: 1px;
        margin: 0;
        background-color: $medium-blue;
        .rw-placeholder {
          @extend h1;
          line-height: 50px !important;
          color: $white;
        }
        p {
          color: $medium-brown;
          line-height: 20px;
        }
      }
      .rw-i.rw-i-caret-down {
        line-height: 50px;
      }
      input.rw-input {
        height: 24px;
        margin-top: 3px;
      }
      ul {
        text-align: left;
      }
    }
    .sitter-list-item {

      display: flex;
      justify-content: space-between;
      align-items: center;
      .zone-container {
        display: flex;
        align-items: center;
      }
    }
  }
}

.new-client-appt-tooltip {
  width: 300px;
  .rc-tooltip-content {
    .rc-tooltip-arrow {
      border-bottom-color: $white;
    }
    .rc-tooltip-inner {
      background-color: $white;
      color: $black;
      box-shadow: 0px 2px 4px #E0DDD6;
      padding: 0;
      .new-client-appt-tooltip-body {
        @extend .noselect;
        cursor: default;
        position: relative;
        .close-x {
          position: absolute;
          top: 14px;
          right: 18px;
          padding: 0;
          color: $medium-brown;
          font-size: 16px;
          cursor: pointer;
          &:hover {
            color: $dark-brown;
          }
        }
        .new-client-appt-tooltip-form-section {
          display: flex;
          flex-direction: column;
          border-top: 1px solid $light-brown;
          > div {
            display: flex;
            justify-content: space-around;
            align-items: center;
            .simple-checkbox-input {
              margin-top: 0;
            }
            > div {
              flex: 1;
            }
          }
        }
        > div {
          padding: 14px 18px;
          > div {
            &:first-child {
              margin-bottom: 12px;
            }
          }
        }
        p {
          margin: 0 0 4px 0;
          font-size: 14px;
          color: $dark-brown;
          &.minor-heading {
            color: $medium-brown;
          }
        }
      }
    }
  }
}
