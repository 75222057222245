.promo-form-container{
  margin:auto;
  .info{
    height:100px;
  }
}
.promo-actions{
  padding: 1em;
}

.promo-table{
  background-color: #d5d5d5;
  border: 1px solid #3aafa9;
  padding: 1.25em;
  border-radius: 3px;
  .table-headers{
    border-bottom: 1px solid #3aafa9;
    margin: .25em;
    .header{
      text-align: center;
      padding-bottom:1em;
      color: #3E4649;
      letter-spacing: .5px;
      text-transform: uppercase;
    }
  }
  .table-row{
    display: flex;
    width: 100%;
    padding: 10px;
    margin: 0;
    align-items: center;
    .cell{
      text-align: center;
    }
  }
  .table-row:hover{
    background-color: #fff;
    transition: all .2s ease-in-out;
    box-shadow: 0 0 20px rgba(0, 0, 0, 0.2);
    border: 1px solid #3aafa9;
  }
}

.promo-tabs{
  display: inline-flex;
}
.promoTab{
  padding: 10px;
  border-radius: 3px 3px 0 0;
  border: 1px solid $light-gray;
  border-bottom: none;
  cursor: pointer;
  background: $light-gray;
  margin-right: 3px;
}
.promoTab.selectedTab{
  border: 1px solid #3aafa9;
  background: #d5d5d5;
  border-bottom: none;
  position:relative;
}
