.UnassignedAppointments, .AssignmentPanel {
  padding: 0;
}
.AssignmentPanel{
  margin-left: 10px;
  margin-top: 10px;
}

.approveAll{
  width: 400px;
}


.sitter-list-item {
  background-color: $white;
  padding: 15px;
  display: flex;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  justify-content: space-between;
  align-items: center;
  .zone-container {
    display: flex;
    align-items: center;
  }
}

.AssignmentPanel-PC-cancel {
  display: flex;
  span { margin-left: 3px; }
  align-items: center;
  align-content: center;
  justify-content: center;
  line-height: 26px;
  width: 26px;
  height: 26px;
  border-radius: 50%;
  padding: 0;
}

i.fa.fa-exclamation-triangle.fa-5x{
  color:$alert-light-red;
}

//Unassigned Appointments Bucket
.unassigned-appointments{

  display: inline-block;
  width: 100%;
  height: 100%;
  // padding: 10px;
  h1{
    text-align: center;
    padding: 5px 10px;
  }
  .pagination{
    margin: 15px 5px 5px 5px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    p{
      display: inline-block;
      margin: 10px auto 0;
    }
  }
  .pet-card-container{
    padding: 0 10px 0 0;
  }



  @media (max-width: $large){
    position: relative;
    top:0;
    width: 100%;
    overflow: scroll;
    h1{
      display: block;
    }
    .pagination{
      display: none;
    }
    .pet-card-container{
      display: flex;
      .pet-card{
        margin: 10px;
      }
    }
  }

  @media (max-width: 992px) {
    height: 250px;
  }

  .unassigned-filter{
    padding: 0 10px 10px 0;

    .form-group.input-field{
      max-height: 999px;
      border: 1px solid $medium-brown;
      display: flex;
      margin-bottom: 0px;

      #rw_106 {
        border-radius: 0;
      }
      .search-icon{
        display: flex;
        align-items: center;
        background-color: $gray-lighter;
        padding: 0 10px;
        // width: 14px;
        span.fa.fa-search{
          position: inherit;
        }
      }
      .rw-multiselect{
        @extend .noselect;
        width: 100%;
        padding: 0 10px;
        input{
          width: inherit;
          padding: 0;
          &:focus{
            outline: none !important;
          }
        }
        .rw-multiselect-wrapper{
          padding: 7px 0 6px 0;
          height: 100%;
          ul{
            li{
              padding-top: 0px;
              &:last-of-type{
                margin-right: 10px;
              }
            }
          }
        }
      }
    }
  }
}

.SchedulingTool-container {
  display: flex;
  align-items: stretch;
}

//Scheduler
.scheduler{
  top: 154px;
  margin: 0 0 50px 0;
  // min-width: 600px;
  overflow-y: scroll;
  overflow-x: scroll;
  min-height: 150vh;
  justify-content: flex-start;

  &:hover {
    box-shadow: 0 0 15px rgba(0, 0, 0, .2);
  }
  .date-switcher{
    display: flex;
    // min-width:$large;
    background-color: $white;
    width: 100%;
    justify-content: space-between;
    align-items: center;
    padding: 20px 0;
    text-align: center;
    border-bottom: 1px solid $light-brown;
    .pagination{
      margin: 0 15px;
      &.disabled{
        border-color: $medium-brown;
      }
    }
  }
  .date-row{
    background-color: white;
    display: flex;
    justify-content: center;
    padding: 15px 0 15px 60px;
    margin-bottom: 2px;
    color: $black;
    font-size: 16px;
    @extend .noselect;
  }
  .scheduler-row{
    display: flex;
    flex-direction: row;
    .corner-block{
      background-color: $white;
      min-width: 60px;
      height: 50px;
      border-right: 1px solid $light-brown;
      border-bottom: 1px solid $light-brown;
    }
    .time-block{
      background-color: $white;
      width: 60px;
      min-width: 60px;
      min-height: 107px;
      font-family: $light-font;
      font-size: 12px;
      color: $dark-brown;
      border-right: 1px solid $light-brown;
      border-bottom: 1px solid $light-brown;
      padding: 20px 10px 20px;
      word-wrap: normal;
      text-align: center;
    }
  }
  .staff-block{
    background-color: $white;
    height: 50px;
    min-width: 200px;
     // even width
    text-align: center;
    border-bottom: 1px solid $light-brown;
    border-right: 1px solid $gray-lighter;
    padding: 0;
    flex: 1;
    h1{
      margin: 0;
    }
    p{
      margin: 0;
      font-size: 11px;
      text-transform: uppercase;
      height: 16px;
      margin-top: -3px;
    }
    span.fa{
      color: $light-brown;
      display: inline-block;
      float: right;
      margin: -30px 15px 0 0;
    }
    .rw-dropdownlist{
      height: 100%;
      &:hover {
        background-color: $gray-lighter;

        .rw-select.rw-btn {
          background-color: $gray-light;
          &:hover {
            background-color: $gray;
          }
        }
        .rw-filter-input .rw-select.rw-btn {
          background-color: transparent;
          &:hover { color: $medium-blue; }
        }
        .rw-placeholder { color: $gray !important; }
      }
      .rw-popup-container {
        li { border: 0; }
        li.rw-state-focus:hover {
          background-color: #e6e6e6;
        }
        li.rw-list-option {
          padding: 5px 5px 5px 10px;
        }

      }
      .rw-input{
        height: 100%;
        margin: 0;
        display: flex;
        align-items: center;
        justify-content: center;

        .rw-placeholder{
          font-family: 'Nunito';
          font-weight: 100;
          font-size: 14px;
          line-height: 28px;
          margin: 10px 0;
          @extend .noselect;
          line-height: 50px !important;
          color: $medium-brown;
        }
        p{
          color: $medium-brown;
          line-height: 20px;
        }
      }
      .rw-i.rw-i-caret-down{
        line-height: 50px;
      }
      input.rw-input{
        height: 24px;
        margin-top: 3px;
      }
      ul {
        text-align: left;
      }
      .sitter-list-item{
        display: flex;
        justify-content: space-between;
        align-items: center;
        background-color: $white;
        .zone-container{
          display: flex;
          align-items: center;
        }
      }
    }
    &:last-of-type{
      border-right: none;
    }
  }
  .staff-block-name{
    height: 50px;
  }

  .time-slot{
    background-color: $white;
    min-height: 60px;
    width: 0;
    border-bottom: 1px solid $gray-lighter;
    border-right: 1px solid $gray-lighter;
    display: flex;
    justify-content: flex-start;
    flex-direction: column;
    flex: 1;
    padding: 3px;
    &:last-of-type{
      border-right: none;
    }
    .PetCard-selected-text { display: none; }
    .pet-card{
      margin: 0;
      // margin: 10px 15px 5px;
      // &:last-of-type{
      //   margin: 10px;
      // }
      .client-info-wrapper {
        padding: 0;
      }
      &.selected {
        opacity: 1;
        background-color: transparent;
        border: 0;
        filter: alpha(opacity=100);
      }
    }
    .hot-spot{
      @extend .pet-card;
      display: flex;
      align-items: center;
      justify-content: center;
      color: $light-brown;
      border: 1px dashed $light-brown;
      height: 82px;
      &:hover{
        background-color: $light-gray;
      }
    }
  }
  @media (max-width: $large){
    margin: 10px 0;
  }
  @media (max-width: 1047px){
    overflow-x: scroll;
  }
}
