// Pet Card (For Add Pet Overlay Modal)
.add-pet-card-wrapper {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  cursor: pointer;
  @extend .noselect;
  .add-pet-card {
    background-color: $white;
    border: 1px solid $light-brown;
    border-radius: 3px;
    margin: 10px 14px;
    min-width: 200px;
    width: 300px;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    padding: 12px;
    color: $black;
    font-weight: 500;

    &:hover {
      @extend .approved;
      color: $approved;
      box-shadow: 0 0 20px rgba(0, 0, 0, .1);
      transition: all .2s ease-in-out;
    }
    &.selected {
      @extend .selected;
      color: $dark-blue;
    }
    p {
      margin: 0;
      font-size: 15px;
      line-height: 22px;
    }
    .pet-img{
      width: 42px;
      height: 42px;
      background-color: $light-brown;
      border-radius: 84px;
      margin-right: 12px;
      overflow: hidden;
      background-size: cover;
    }
  }
}


// Pet Row (For Appointment Detail/Edit Views)
.pet-row {
  background-color: $dark-blue;
  border: 1px solid $dark-blue;
  border-radius: 33px;
  margin: 5px 0;
  overflow: visible;
  cursor: pointer;

  &:hover {
    background-color: $medium-blue;
    box-shadow: 0 0 15px rgba(0, 0, 0, 0.2);
  }
  p {
    margin: 0;
    color: $white;
    letter-spacing: 1px;
    font-size: 18px;
    line-height: 22px;
    font-weight: 500;
  }
}

.pet-row-top {
  display: flex;
  justify-content: space-between;
  padding: 10px;

  .pet-info{
    display: flex;
    align-items: center;
    p{
      margin-right: 20px;
    }
    .pet-img{
      width: 44px;
      height: 44px;
      background-color: $light-brown;
      border-radius: 22px;
      margin-right: 15px;
      border: 2px solid white;
      overflow: hidden;
      background-size: cover;
    }
  }
  .pet-actions {
    display: flex;
    align-items: center;

    .btn { color: white;
      &:hover {
        color: $dark-brown;
      }
    }

    .close-button {
      width: 30px;
      height: 30px;
      line-height: 30px;
      text-align: center;
      border-radius: 15px;
      font-size: 18px;
      color: white;
      display: inline-block;

      &:hover {
        cursor: pointer;
        background-color: $alert-success-bg;

        .fa {
            color: $dark-brown;
        }
      }
    }

  }
}

.pet-row-details {
  background-color: white;
  padding: 0;
  @extend .noselect;
  .pet-row-details-header {
    text-transform: uppercase;
    color: $dark-brown;
    font-weight: 500;
    background-color: $light-brown;
    padding: 6px 12px 4px 12px;
    font-size: 13px;
  }
  .pet-row-details-item {
    display: flex;
    padding: 10px 12px 9px 12px;
    border-top: 1px solid $light-brown;
    &:last-child {
      padding-bottom: 20px;
    }
    p {
      font-size: 13px;
      color: $dark-brown;
    }
    .title-cell {
      flex: 0 0 150px;
    }
    .value-cell {
      flex: 1;
      p {
        color: $medium-brown;
      }
    }
  }
}
