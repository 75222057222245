//Calendar Day View

html {
    overflow: scroll;
    overflow-x: hidden;
}
::-webkit-scrollbar {
    width: 0px;  /* remove scrollbar space */
    background: transparent;  /* optional: just make scrollbar invisible */
}
/* optional: show position indicator in red */
::-webkit-scrollbar-thumb {
    // background: #FF0000;
}

.canceledThing{
  display: none;
}

.pendingMarker{
  z-index: 999;
}

.sitterPreview{
  position: fixed;
  bottom: 0px;
  overflow-y: scroll;
  overflow-x: hidden;
  left: 1%;
  padding: 10px;
  max-height: 75%;
  z-index: 999;
  background-color: $white;
  transition: .5s all ease;
  h3{
    display: inline-block;
    width: 90%;
  }
  button{
    display: inline-block;
    width: 10%;
  }
  .apptCont{
    position: relative;
  }
  .timesLeft{
    position: relative;
    display: inline-block;
    width: 9%;
    background-color: $light-blue;
  }
  .timesRight{
    position: relative;
    display: inline-block;
    width: 91%;
  }
}

.sitterPreview::-webkit-scrollbar {
  width: 0px;  /* remove scrollbar space */
  background: transparent;  /* optional: just make scrollbar invisible */
}

.sitter-list::-webkit-scrollbar {
  width: 0px;  /* remove scrollbar space */
  background: transparent;  /* optional: just make scrollbar invisible */
}

.specialBtn{
  position: fixed;
  bottom: 0;
  right: 0;
}

.specialSaveBtn{
  background-color: $light-gray !important;
  cursor: default !important;
}

.specialSaveBtnValid{
  background-color: $light-blue !important;
  cursor: pointer !important;
}

.apptHeader{
  font-weight: bold;
  padding: 5px 0;
  margin: 5px;
}

.sitter-list{
  background-color: $white;
  position: fixed;
  bottom: 0px;
  overflow-y: scroll;
  overflow-x: scroll;
  width: 18%;
  max-height: 75%;
  z-index: 998;
  border-radius: 5px;
  color: darken($dark-blue, 10);
  .sitter-show-appts{
    position: absolute;
    top: 0;
    right: -50%;
    height: 500px;

  }
  .sitter-item{
    padding: 10px;
    color: darken($dark-blue, 10);
    div{
      padding: 0;
      height: 25px;
      line-height: 25px;
      vertical-align: middle;
    }
    i{

    }
  }
  .sitter-item-appt{
    padding-left: 0px;
    cursor: pointer;
    span{
      margin: 3px 10px;
    }
    &:hover{
      border-right: 1px solid $light-blue;
      border-bottom: 1px solid $light-blue;
    }
  }
  .sitter-appts-name{
    cursor: pointer;
  }
  .sitter-completed{
    font-style: italic;
    text-decoration: line-through;
  }
}

.gonna_be_late{
  color: red !important;
}

@keyframes flash {
    from {color: black;}
    to {color: red;}
}


.appt-item{
  vertical-align: top;
  display: inline-block;
  width: auto;
  margin: 0 5px;
  color: $dark-blue;
  .appt-title{
    cursor: pointer;
    position: relative;
    top: 0;
    padding: 10px;
  }
  i{
    cursor: pointer;
  }
  .appt-list-item{
    padding: 4px;
    line-height: 20px;
    cursor: pointer;
    span{
      margin: 0 5px;
    }
    z-index: 999;
  }
  .pending{
    background-color: lighten($pending, 20);
    border: 1px solid $pending;
  }
  .edit{
    background-color: lighten($change-requested, 20);
    border: 1px solid $change-requested;
  }
  .canceled{
    background-color: lighten($alert-light-red, 5);
    border: 1px solid $alert-light-red;
  }
  .approvedUnassigned{
    background-color: lighten($pending, 20);
    border: 1px solid $alert-light-red;
  }
  .approved{
    background-color: lighten($approved, 20);
    border: 1px solid $approved;
  }
  .consultation{
    background-color: $white;
    border: 1px solid $pending;
  }
}

.appt-title:hover{
  background-color: $light-gray;
}

.map-container {
  overflow: visible;
  .edit{
    background-color: lighten($change-requested, 20);
    border: 1px solid $change-requested;
  }
    height: calc(100vh - 128px);
    width: 100vw;

    position: relative;
    .cpend { color: #777777; }
    .cpappr { color: #000000; }
    .c1 { color: #d92121; }
    .c2 { color: #b26750; }
    .c3 { color: #ff7700; }
    .c4 { color: #8c6200; }
    .c5 { color: #d9c8a3; }
    .c6 { color: #ffbe26; }
    .c7 { color: #5d662e; }
    .c8 { color: #dbff26; }
    .c9 { color: #52b31b; }
    .c10 { color: #99ffbb; }
    .c11 { color: #006652; }
    .c12 { color: #1495cc; }
    .c13 { color: #0083b3; }
    .c14 { color: #0059b3; }
    .c15 { color: #bfdfff; }
    .c16 { color: #8687b3; }
    .c17 { color: #ffccff; }
    .c18 { color: #0008ff; }
    .c19 { color: #801bb3; }
    .c20 { color: #bc82d9; }
    .c21 { color: #664d5d; }
    .c22 { color: #ff00a2; }
    .c23 { color: #ff9999; }
    .c24 { color: #d9a3ac; }
    .fa-circle.fa-stack-2x {
      -webkit-text-stroke-width: 1px;
      -webkit-text-stroke-color: black;
    }
    .fa-square {
      -webkit-text-stroke-width: 1px;
      -webkit-text-stroke-color: black;
    }
    .fa-stack-1x {
      color: #000000;
    }
}

.draftMode{
  border: 3px solid blue;
}

.pulse {
  width: 10px;
  height: 10px;
  border: 5px solid #f7f14c;
  -webkit-border-radius: 30px;
  -moz-border-radius: 30px;
  border-radius: 30px;
  background-color: #716f42;
  z-index: 999;
  position: absolute;
}

.dot {
  border: 10px solid #fff601;
  background: transparent;

}

@-moz-keyframes pulse {
 0% {
    -moz-transform: scale(0);
    opacity: 0.0;
 }
 25% {
    -moz-transform: scale(0.25);
    opacity: 0.5;
 }
 50% {
    -moz-transform: scale(0.5);
    opacity: .8;
 }
 75% {
    -moz-transform: scale(0.75);
    opacity: 1;
 }
 100% {
    -moz-transform: scale(1);
    opacity: 0.0;
 }
}

@-webkit-keyframes "pulse" {
 0% {
    -webkit-transform: scale(0);
    opacity: 0.0;
 }
 25% {
    -webkit-transform: scale(0);
    opacity: 0.5;
 }
 50% {
    -webkit-transform: scale(0.1);
    opacity: 0.8;
 }
 75% {
    -webkit-transform: scale(0.5);
    opacity: 1;
 }
 100% {
    -webkit-transform: scale(1);
    opacity: 0.0;
 }
}

.highlight{
  .i-base{
    text-shadow:
      2px 2px 6px rgba(0, 0, 0, .5),
      -2px 2px 6px rgba(0, 0, 0, .5),
      2px -2px 6px rgba(0, 0, 0, .5),
      -2px -2px 6px rgba(0, 0, 0, .5);
  }
  animation:1000ms pulseIn ease-in-out infinite;
	-webkit-animation:1000ms pulseIn ease-in-out infinite;
}

@keyframes pulseIn{
			0%{
				opacity:.5;
				transform:scale(0.5,0.5);
			}
			50%{
				opacity:1;
				transform:scale(1.2,1.2);
			}
			100%{
				opacity:.5;
				transform:scale(0.5,0.5);
			}
		}

		@-webkit-keyframes pulseIn{
			0%{
				opacity:.5;
				-webkit-transform:scale(0.5,0.5);
			}
			50%{
				opacity:1;
				-webkit-transform:scale(1.2,1.2);
			}
			100%{
				opacity:.5;
				-webkit-transform:scale(0.5,0.5);
			}
		}

.date-switcher{
  display: flex;
  background-color: $white;
  width: 100%;
  justify-content: space-between;
  align-items: center;
  padding: 20px 0 0;
  text-align: center;
  .pagination{
    margin: 0 15px;
  }
}

.apptWindow{
  transition: .25s all ease;
  z-index: 998;
}

.breakWindow{

}

.specialToggle{
  i{
    position: absolute;
    right: 2px;
    transition: transform 150ms ease;
    cursor: pointer;
  }
}

.toggleDown{
  transform: rotate( -180deg );
  transition: transform 250ms ease;
}

.apptWindow:hover{
  // background-color: $light-gray;
  border-left: 2px solid black;
  z-index: 999;
}

.mapAppointmentRow{
  background-color: $light-blue;
  padding: 5px;
  div{
    padding:0;
  }
  min-height: 30px;

  margin-left: 0;
}
.mapAppointmentRow:hover{
  border: 1px solid $dark-blue;
}

.hiddenRow{
}

.breakWindow{
  background-color: $dark-blue;
  color: $white;
  font-weight: bold;
  z-index: 200;
}

.addOnButtons{
  div{
    padding-top: 40px;
    padding-left: 40px;
    // button{
    //   margin: 0 auto;
    // }
  }
}

.addOnRow{
  padding-top: 10px;
  padding-bottom: 10px;
}

.mapBreakRow{
  padding: 4px;
  padding-right: 15px;
  div{
    padding:0;
  }

  input{
    height: 22px;
    width: 80%;
  }

  margin-left: 0;
  button{
    width: auto;
    height: 22px;
    line-height: 22px;
    vertical-align: middle;
    margin: 0;
    margin-right: 5px;
    padding: 0px 4px;
  }
  .cancelBreakBtn{
    float: right;
  }
  .cancelll{
    background-color: $light-gray;
    color: $black;
  }
}

.editBreakBtn{
  float: right;
}

.editDefaultTimes{
  width: 75px !important;
}

.editDefaultTimesBox{
  margin-top: -13px !important;
}

.en_route_row{
  background-color: lighten($purple, 20);
}
.completed_row{
  background-color: $light-gray;
}
.in_progress_row{
  background-color: $alert-light-orange;
}

.special{
  background-color: $pending;
}

.late_visit{
  background-color: $alert-light-red;
  border: 1px solid $alert-dark-red;
}

.specialCar{
  position: absolute;
  right: 0;
  animation: car 2s infinite;
}

.idle_time{
  z-index: -1;
  background-image:
    repeating-linear-gradient(
      45deg,
      $light-gray,
      $light-gray 5px,
      $white 10px,
      $white 20px /* determines size */
    );
  text-align: center;
  border: 1px
}

.stackers {
  margin-left: 22px;
}


@keyframes car
{
    0% {
        right: 0;
    }
    50% {
        right: -10%;
    }
    100% {
      right: 0;
    }

}

.carCont{
  position: relative;
}
.specialFancyCar{
  position: absolute;
  animation: moveX 1.05s linear 0s infinite alternate, moveY 1.4s linear 0s infinite alternate;
}

@keyframes moveX {
  from { left: -1px; } to { left: 1px; }
}


@keyframes moveY {
  from { top: -1px; } to { top: 1px; }
}



.enRoute{
  background-color: $dark-blue;
}

.mapAppointmentDetail{
  .rw-input{
    padding-left: 0 !important;
    font-size: 16px;
    margin-bottom: 10px;
  }
  .mapCalendarRows{
    .timesLeft{
      position: relative;
      display: inline-block;
      width: 15%;
    }
    .timesRight{
      position: relative;
      display: inline-block;
      width: 85%;
    }
    overflow: hidden;
    overflow-y: scroll;
  }

  .apptWindow:hover{
    // background-color: $light-gray;
    border-left: 2px solid black;
  }
  position: absolute;
  z-index: 1999;
  right: 20px;
  height: auto;
  width: 650px;
  bottom: 10px;
  background-color: $white;
  .pet-card{
    margin: 0;
  }
  .row{
    margin: 0;
  }
  .petMarkerLeft{
    padding: 0;
  }
  .petMarkerRight{
    button{
      height: 40px;
      margin-top: 10px;
      margin-right: 10px;
    }
  }



  .mapAppointmentRow{
    background-color: $light-blue;
    padding: 5px;
    div{
      padding:0;
    }
    min-height: 30px;
    .specialTimeBlock{
      z-index: 999;
    }
  }
  .selectedAppt{
    border: 2px solid $dark-blue;
    font-style: italic;
  }
}

.schedulerDetail {
  // position: absolute;
  // bottom: 20px;
  // right: 20px;
  // height: 550px;
  // width: 1050px;
  background-color: $white;
}

.breakInput{
  border-radius: 4px;
  border: 1px solid $light-gray;
  margin-left: 10px;
  margin-right: 10px;
  width: 35%;
  padding-left: 5px;
  padding-right: 5px;
}

.scheduleCard{
  background-color: $white;
  width: 140%;
  height: 500px;
  label{
    font-weight: normal;
    margin-right: 10px;
  }
  // height: 650px !important;
  overflow-y: scroll;
  // padding-top: 0 !important;
  z-index: 1000;
  .scheduleHeader{
    padding: 0;
  }
  button{
    height: 40px;
    margin: 5px;
  }
  h3{
    background-color: $dark-blue;
    color: $white;
    text-align: center;
    margin: 0;
    padding: 5px;
  }
  .topSection{
    // padding: 10px;
    i{
      margin: 0 5px;
    }
    div{
      margin: 0 0 6px 0px;
    }
  }

  .selectZone{
    display: inline-block;
    width: 12%;
  }

  .nameZones{
  }

  .sitterStuff{
    padding: 5px;
    margin: 5px 0;
    background-color: $background-blue;
  }

  .teamMemberSettings{
    height: 40px;
    padding: 10px;
  }

  .bottomSection{
    margin-top: 10px;
    padding: 5px;
    overflow-y: scroll;
  }
}

.mapAppointmentDetailSettings{
  height: 30px;
  label{
    float: left;
    font-weight: normal;
  }
  input{
    float: right;
    margin-right: 10px;
  }
  h3{
    clear: both;
    margin: 0;
  }
}

.maplist{
  width: auto;
  min-width: 200px;
  position: absolute;
  top: 2px;
  right: 17px;
  z-index: 980;
  background-color: $white;
  border-radius: 5px;
  overflow-y: visible;
  padding: 0px;
  padding-left: 5px;
  max-height: 60px;
  line-height: 60px;
  vertical-align: middle;
  .list-item{
    margin: 0;
    margin-left: 5px;
    padding: 3px;
    cursor: pointer;
  }
  .edit{
    background-color: lighten($change-requested, 20);
    border: 1px solid $change-requested;
  }
  .canceled{
    background-color: lighten($alert-light-red, 5);
    border: 1px solid $alert-light-red;
  }
  label{
    margin: 0px 5px;
  }
  input{
    margin: 0 5px;
  }
  button{
    margin: 5px;
    height: 40px;
  }
}
.mapStatus{
  width: 300px;
  z-index: 998;
  width: 600px;
  line-height: 40px;
  vertical-align: middle;
}


.petMarkerDetail{
  cursor: default;
  z-index: 999;
  background-color: $white;
  .closeButton{
    cursor: pointer;
    float: right;
    height: 40px;
    width: 40px;
    color: $black-blue;
    text-align: center;
    line-height: 40px;
    vertical-align: middle;
  }
  .closeButton:hover{
    background-color: $light-blue;
    color: $white;
  }

  .map-pending{
    background-color: #FED866;
    opacity: .8;
    cursor: default;
  }

  .map-approved{
    background-color: #AFD47B;
    cursor: default;
    opacity: .8;
  }

  .map-closed{
    cursor: default;
    opacity: 1;
    background-color: $shade;
    color: black;
  }
}

.calendar-header-day{
  display: flex;
  background-color: $white;
  width: 100%;
  justify-content: space-between;
  align-items: center;
  padding: 20px 0;
  border-bottom: 1px solid $light-brown;
  text-align: center;
  .pagination{
    margin: 0 15px;
  }
}
.calendar-row-day{
  display: flex;
  width: 100%;
  background-color: $white;
  .time-block{
    display: flex;
    justify-content: center;
    align-items: center;
    min-width: 100px;
    min-height: 107px;
    border-bottom: 1px solid $light-brown;
    border-right: 1px solid $light-brown;
    font-family: $light-font;
    font-size: 12px;
    color: $dark-brown;
    p{
      margin: 0;
    }
  }
  .time-slot{
    display: flex;
    flex-flow: row wrap;
    justify-content: flex-start;
    min-height: 107px;
    border-bottom: 1px solid $light-brown;
    width: 100%;
    .pet-card{
      margin: 5px;
      width: 345px;
    }
  }
  &:last-of-type{
    margin-bottom: 50px;
  }
}

//Calendar Week View
.calendar-header-week{
  position: relative;
  display: flex;
  background-color: $white;
  width: 100%;
  align-items: center;
  text-align: center;
  border-bottom: 1px solid $light-brown;
  .day-wrapper{
    display: flex;
    max-height: 100px;
    width: 13%;
    justify-content: center;
    padding: 20px 0;
    h4{
      color: $black;
    }
  }
  .day-wrapper.current-day{
    background-color: rgba($light-blue, 0.1);
  }
  .spacer{
    display: flex;
    justify-content: center;
    align-items: center;
    min-width: 85px;
    min-height: 85px;
    .pagination{
      margin: 0;
    }
  }
}
.calendar-row-week{
  display: flex;
  background-color: $white;
  .time-block{
    display: flex;
    justify-content: center;
    align-items: center;
    min-width: 85px;
    min-height: 85px;
    border-bottom: 1px solid $light-brown;
    border-right: 1px solid $light-brown;
    font-family: $light-font;
    font-size: 12px;
    color: $dark-brown;
    p{
      margin: 0;
    }
  }
  .day-slot{
    flex-direction: row;
    justify-content: flex-start;
    flex-wrap: wrap;
    min-height: 107px;
    border-bottom: 1px solid $light-brown;
    border-right: 1px solid $light-brown;
    width: 13%;
    overflow-x: hidden;
    .current-time-indicator{
      background-color: $alert-dark-red;
      height: 1px;
      width: 100%;
    }
  }
  &:last-of-type{
    margin-bottom: 50px;
  }
  .day-slot.current-day{
    background-color: rgba($light-blue, 0.1);
  }
  .spacer{
    justify-content: center;
    align-items: center;
    min-width: 85px;
    min-height: 85px;
  }
}

//Calendar Month View
.calendar-header-month, .calendar-header-day{
  .date-switcher{
    display: flex;
    background-color: $white;
    width: 100%;
    justify-content: space-between;
    align-items: center;
    padding: 20px 0 0;
    text-align: center;
    .pagination{
      margin: 0 15px;
    }
  }
}
.calendar-month-wrapper{
  width: 100%;
  background-color: $white;
  padding: 20px 20px 70px;
  margin: 0;

  .calendar-row-month{
    display: flex;
    justify-content: center;
    background-color: $white;
    .day-block{
      flex-direction: row;
      justify-content: flex-start;
      flex-wrap: wrap;
      border-top: 1px solid $light-brown;
      border-left: 1px solid $light-brown;
      flex: 1;
      width: 0; // force equal width (no growing)
      &:last-of-type{
        border-right: 1px solid $light-brown;
      }
      .date-row{
        height: 35px;
        padding: 10px;
        cursor: default;
        p.active{
          font-size: 13px;
          color: $dark-brown;
          margin: 0;
          @extend .noselect;
        }
        p.inactive{
          font-size: 13px;
          color: $medium-brown;
          margin: 0;
          @extend .noselect;
        }
        p.today{
          font-size: 13px;
          font-weight: bold;
          color: $medium-blue;
          @extend .noselect;
        }
      }
    }
    .day-wrapper{
      display: flex;
      max-height: 100px;
      justify-content: center;
      flex: 1;
      width: 0; // force equal width (no growing)
      h4{
        color: $black;
      }
    }
    &:last-of-type{
      .day-block{
        border-bottom: 1px solid $light-brown;
      }
    }
  }
}

//View More Appointments (Week and Month Calendar View)
.view-more{
  position: relative;
  background-color: rgba($light-brown, 0.4);
  padding: 5px 0;
  text-align: center;
  p{
    font-family: $light-font;
    color: $dark-brown;
    font-size: 13px;
    margin: 0;
  }
}
