.legend-bar{
  position: fixed;
  bottom: 0;
  left: 0;
  width: 100%;
  background-color: $light-brown;
  height: 30px;
  z-index: 2;
  display: flex;
  justify-content: center;
  align-items: center;
  .legend-item{
    padding: 0 15px;
    .color{
      width: 10px;
      height: 10px;
      border-radius: 10px;
      display: inline-block;
      padding: 0;
      margin: 0;
    }
    p{
      display: inline-block;
      color: $black;
      font-size: 12px;
      margin: 2px 5px;
    }
    .completed{
      background-color: $completed;
    }
    .in-progress{
      background-color: $in-progress;
    }
    .approved{
      background-color: $approved;
    }
    .pending{
      background-color: $pending;
    }
    .change-requested{
      background-color: $change-requested;
    }
    .canceled{
      background-color: $change-requested;
    }
  }
}
