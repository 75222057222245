.zip-actions {
  .btn {
    margin: 5px 10px;
  }
}
.zip-card {
  margin: 5px 10px;
  background-color: #d5d5d5;
  border-radius: 3px;
  border: 1px solid #3aafa9;
  h2 {
    border-bottom: 1px solid #3aafa9;
    font-weight: bold;
  }
  .zip-row {
    display: flex;
    width: 100%;
    padding: 10px;
    margin: 0;
    align-items: center;
    .zip-code {
      font-size: 1.25em;
    }
    i {
      margin: 0 3px;
    }
    .fa-times {
      color: #d9534f;
    }
    i:hover {
      cursor: pointer;
    }
    .fa-toggle-on {
      color: #3aafa9;
    }
  }
  .zip-row:hover {
    background-color: #fff;
    transition: all 0.2s ease-in-out;
    box-shadow: 0 0 20px rgba(0, 0, 0, 0.2);
    border: 1px solid #3aafa9;
  }
}
.add-zip-modal {
  margin: 40px 0;
  input {
    padding: 10px 5px;
    border: 1px solid $light_blue;
    border-radius: 3px;
  }
  div {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
}

.areaTitle {
  font-weight: bold;
}

.addFacility {
  padding: 20px 0;
  input {
    border: 1px solid $light-gray;
    border-radius: 4px;
  }
  button {
    height: 40px;
    width: 80px;
  }
}
.addFacilityBtn {
  margin: 20px 0;
}

.facility {
  padding: 20px;
}

.facilityInfo {
  label {
    display: block;
  }
  input {
    margin-top: 2px;
    line-height: 28px;
    border: 1px solid $light-gray;
    border-radius: 4px;
    height: 24px;
  }
  h4 {
    // color: $black;
    // font-weight: bold;
  }
  .rw-widget {
    border: 1px solid $light-gray !important;
  }
  // .rw-input{
  //   border: 1px solid $light-gray !important;
  // }
  // .rw-multiselect-wrapper{
  //   border: 1px solid $light-gray !important;
  // }
}

.centeredH5 {
  text-align: center;
}

.addAreas {
  padding: 30px 0;
}

.cancelBtn {
  margin: 0;
  float: right;
  position: absolute;
  top: 45px;
  right: 0px;
}

.editBoarding {
  justify-content: flex-start;
}

.boarding {
  .boardingDays {
    min-height: 75px;
    .editBoardingWeekday {
      width: 50px;
      margin-top: -5.5px;
    }
  }
  input {
    margin-top: 2px;
    line-height: 28px;
    border: 1px solid $light-gray;
    border-radius: 4px;
    height: 24px;
  }
  .rw-widget {
    border: 1px solid $light-gray !important;
  }
  // .rw-input{
  //   border: 1px solid $light-gray !important;
  // }
  // .rw-multiselect-wrapper{
  //   border: 1px solid $light-gray !important;
  // }
}
.area {
  padding: 15px;
}

.addArea {
  input {
    border: 1px solid $light-gray;
    border-radius: 4px;
    width: 100%;
  }
  .rw-input {
    border: 1px solid $light-gray;
  }
  .rw-multiselect-wrapper {
    border: 1px solid $light-gray;
  }
}

.kennels {
  input {
    width: 90%;
  }
  .specialOne {
    padding-right: 0;
    min-height: 40px;
  }
}

.rowSection {
  padding: 20px 15px;
  margin-bottom: 15px;
  clear: both;
}

.facCont {
  padding-top: 45px;
}

.addFac {
  margin-top: 30px;
}

.facLink {
  color: $black;
  .facLinkIn {
    padding: 5px;
    padding-left: 25px;
    margin-bottom: 10px;
  }
}

.facility-form-subheader {
  display: flex;
  flex-direction: row;
  align-items: center;
  .fa {
    margin-left: 5px;
  }
}
