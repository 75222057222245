.error-text {
  color: $alert-dark-red !important;
  .rw-placeholder {
    color: $alert-dark-red !important;
  }
  @include placeholder {
    color: $alert-dark-red !important;
  }
}

.error-border {
  border-color:  $alert-dark-red !important;
}

.error-doubleBorder{
  border: 1px solid $alert-dark-red !important;
}
