.no-access{
  font-size: 16px;
  color: black;
}

.maStuff{
  clear: both;
}

.dropdownthings{
  border: 1px solid black;
}

.maNames{
  padding: 45px 0 0 15px;
  height: 100%;
  div{
    height: 22px;
    margin-bottom: 5px;
  }
}

.maTypes{
  h5{
    margin-left: 5px;
    margin-bottom: 0;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
  }
  div {
    border-left: 1px solid black;
    height: 60px;
    padding: 5px;
    input{
      width: 80%;
      border: 1px solid $light-gray;
      border-radius: 4px;
      margin-bottom: 5px;
    }
  }
  .noBord{
    border-left: none;
    clear: both;
  }
  .extraBord{
    height: 120px;
    .speedLabel{
      font-weight: bold;
      float: left;
    }
    .speedVal{
      float: right;
    }
  }
}

.buttonThing{
  padding-top: 11px;
  button{
    margin: 0 5px;
  }
}

.specialInput{
  input {
    width: 85%;
  }
}

.badInput{
  input{
    border: 1px solid red;
  }
}

.removeNotes{
  position: absolute;
  right: 5px;
  top: 5px;
  z-index: 999;
  height: 30px;
}

.removeNotes:hover{
  background-color: darken($light-gray, 20);
  color: $white;
}

.prompter{
  background-color: $white;
  width: 400px;
  padding: 20px;
  button{
    height: 40px;
    margin: 5px;
  }
}

div.button-container{
  width: 90%;
  position: absolute;
  .btn.btn-primary.pull-right {
    position:absolute;
  }
}


.staffDataContainer{
  text-align: right;
  padding: 10px 30px;

  h3{
    text-align: center;
    font-size: 22px;
    font-weight: bold;
  }
  .dataHeader{
    font-size: 16px;
    font-weight: bold;
    float: left;
  }

  .dataCol{
    height:35px;
  }
}
.fieldSelection{
  padding: 10px 30px;
}

.missingLat{
  border: 1px solid red;
}

.dashboard {
  &-tabs {
    display: flex;
    flex-wrap: nowrap;
    margin: 20px 20px 0 20px;
  }

  &-wrapper {
    .tab-header-title {
      flex-basis: 100%;
      margin: 20px;
      font-family: 'Nunito';
      font-weight: 400;
      color: #40514E;
      font-size: 18px;
      line-height: 28px;
    }

    .comments-wrapper {
      border: 1px solid $medium-blue;
      border-radius: 5px;
      padding: 0px;
      margin-bottom: 20px;
      &:hover {
        box-shadow: 0 0 15px rgba(0, 0, 0, .2);
      }
    }

    &.content {
      display: block;
      padding: 20px;
      margin: 0 auto;
      .dashboard-item {
        padding: 0;

        .consideration-box p span.number {
          background-color: $alert-dark-red;
          color: $orange;
          display: inline-flex;
          padding: 3px 8px;
          border-radius: 3px;
          margin: 0 5px;
        }

        .consideration-box p span.view-details {
          display: inline-flex;
          margin-left: 20px;
          &:hover {
            text-decoration: underline;
            cursor: pointer;
          }
          a {
            color: $alert-dark-red;
          }
        }
      }
    }
  }


  &-tab-item {
    .tab-content {
      margin:0 20px;
      border-radius: 0 0 3px 3px;

      .user-list-container {
        border-right: 1px solid $medium-blue;
        border-left: 1px solid $medium-blue;
        border-bottom: 1px solid $medium-blue;
        max-height: 400px;
        overflow-y: scroll;
        transition: .5s all ease;
        margin: 0;
        padding: 0 10px 10px;
        background-color: $light-gray;

        &.user-list-header {
          border-bottom: 0px $light-gray;
          &:hover {
            background-color: $light-gray !important;
          }
        }
        .user-list-item-content.title {
          font-weight: 400;
          font-size: 12px;

          &:hover {
            color: $medium-blue;
            cursor: pointer;
          }

          span.fa {
            line-height: 40px;
            margin-left: 10px;
          }
        }

        .user-list-item-content {
          justify-content: flex-start;


          &.notes {
            justify-content: space-between;
            align-items: center;

            .btn-admin-details {
              color: $light-gray;
              height: 34px;
              width: 34px;
              line-height: 34px;
              border-radius: 50%;
              display: flex;
              align-items: center;
              justify-content: center;
              font-size: 20px;
            }

            .extra-notes {
              font-size: 12px;
            }
            &:hover {
              .btn-admin-details {
                background-color: $medium-blue;
                color: white !important;
              }
            }
          }
          .showCleared{
            width: 100%;
            height: 100%;
            i{
              float: right;
              font-size: 26px;
            }

            span{
              i{
                float: left;
              }
            }

          }
        }

        .user-list-item.admin-dash { border: 1px solid transparent; }
        .user-list-item.admin-dash:hover {
          border: 1px solid $light-blue !important;
          .btn-admin-details { color: $light-blue; }
        }

        .showCleared:hover{
          label{
            color: black;
          }
          i{
            color: black;
          }
          i:hover{
            cursor: pointer;
          }
        }
      }
      .collapseAppts{
        max-height: 0px;
        transition: .5s all ease;
      }
    }
  }

}



.dashboard-tabs {
  border-bottom: 1px solid #85C8C2;

  .tab-header {
    flex-grow: 1;
    font-family: 'Nunito';
    font-size: 14px;
    text-transform: uppercase;
    letter-spacing: .5px;
    font-weight: 400;
    color: #77736F;
    padding: 0;
    height: 40px;
    line-height: 40px;
    padding: 0 15px;
    cursor: pointer;

    &:hover {
      background-color: $light-gray;
    }

    &.active {
      background-color: $light-blue;
      cursor: default;
      .tab-header-counter {
        background-color: $alert-dark-red;
      }
    }

    &-counter {
      display: inline-block;
      height: 24px;
      width: 24px;
      line-height: 24px;
      text-align: center;
      font-size: 12px;
      border-radius: 12px;
      background-color: $light-brown;
      margin-left: 10px;
      color: white;
      font-weight: 500;
    }
  }
}

.dashboard-item {
  &.comments {
    &-client, &-team-member {
      .container-scroll {
        max-height: 500px;
        overflow-y: scroll;
        margin-bottom: 20px;

        scrollbar-face-color: #367CD2;
        scrollbar-shadow-color: #FFFFFF;
        scrollbar-highlight-color: #FFFFFF;
        scrollbar-3dlight-color: #FFFFFF;
        scrollbar-darkshadow-color: #FFFFFF;
        scrollbar-track-color: #FFFFFF;
        scrollbar-arrow-color: #FFFFFF;

        &::-webkit-scrollbar {
            width: 12px;
        }

        &::-webkit-scrollbar-track {
            -webkit-box-shadow: inset 0 0 6px rgba(0,0,0,0.2);
            -webkit-border-radius: 3px;
            border-radius: 3px;
        }

        &::-webkit-scrollbar-thumb {
            -webkit-border-radius: 3px;
            border-radius: 3px;
            background: $light-blue;
            // -webkit-box-shadow: inset 0 0 6px rgba(0,0,0,0.2);
        }
      }

      .header {
        padding: 5px 20px;
        font-size: 14px;
        background-color: $medium-blue;
        color: white;
        text-transform: uppercase;
        font-weight: 600;
        margin-bottom: 20px;
      }
    }
  }
  .comments {
    &-single {
      &-wrap {
        margin: 0 20px 10px;
        position: relative;
      }
      &-content {
        padding: 10px;
        border: 1px solid $light-brown;
        background-color: $light-gray;
        border-radius: 5px;
        color: $dark-brown;
        &:hover {
          cursor: pointer;
          border-color: $medium-blue;
        }
      }
      &-author {
        color: $medium-blue;
        text-transform: uppercase;
        font-size: 10px;
        letter-spacing: .5px;
        padding-right: 0;
        display: inline;
        margin-left: 10px;

        a { color: $medium-blue;
          &:hover {
            .fa {
              margin-right: 5px;
              transition: .3s all ease-in-out;
            }
          }
        }
      }
    }
    &-meta {
      font-size: 12px;
      padding: 0;
      margin-bottom: 5px;
      display: inline-flex;
      width: 100%;

      .comments-appointment {
        color: $gray-light;
        letter-spacing: .5px;
        text-transform: uppercase;
        font-weight: 600;
        padding: 0;

        a {
          .fa { margin-right: 5px; }
          background-color: $medium-blue;
          color: white;
          line-height: 26px;

          &:hover {
            color: $gray;
            background-color: white;
          }
          padding: 5px 10px;

          &.client {
            background-color: white;
            color: $gray;
            &:hover {
              color: white;
              background-color: $gray-light;
            }
          }
        }

        .fa-long-arrow-right {
          margin: 0 7px 0 10px;
        }

        &:hover {
          color: $medium-blue;
          cursor: pointer;
        }
      }

      .tm-info {
        padding: 0;
        a { color: black;
          &:hover { color: $medium-blue; }
        }
        span.fa { margin: 0 5px; }
      }

    }
  }
}

.specialIconPee{
  position: absolute;
  right: 5px;
  top: 5px;
  color: red;
}

.specialIconPic{
  position: absolute;
  right: 15px;
  top: 5px;
  color: red;
}

.extra-notes {
  display: inline-flex;

  &-item {
    display: inline-flex;
    align-content: center;
    align-items: center;
    justify-content: center;
    height: 26px;
    width: 26px;
    border: 1px solid $medium-blue;
    border-radius: 50%;
    margin-left: 5px;

    &:hover {
      color: white;
      background-color: $medium-blue;
      cursor: pointer;
    }
  }
}

.stats-box {
  margin-top: 20px;

  &-item { margin-bottom: 40px; }

  &-header {
    font-family: 'Nunito';
    font-weight: 400;
    color: #40514E;
    font-size: 18px;
    line-height: 28px;
    margin: 10px 0;
    border-bottom: 1px solid $medium-blue;

    &-sub {
      text-transform: uppercase;
      line-height: 40px;
      color: $medium-blue;
    }
  }

  &-split {
    display: flex;
  }

  &-content {
    &.amount {
      font-size: 24px;
      line-height: 40px;
      font-weight: 600;
      padding-right: 20px;
    }
  }

  .col-split { width: 50%; display: inline-block; }
  .align-right { text-align: right; }
}

.favorite-image-list {
  list-style-type: none;
  .next-button{
    top: 50%;
    position: absolute;
    right: 0;
    margin-right:2em;
  }
  .prev-button{
    top: 50%;
    position: absolute;
    left: 0;
    margin-left: 2em;
  }
  .images-container{
    overflow-y: hidden;
    white-space: nowrap;
    height:auto;
    display: inline-flex;
    width:100%;
    .favorite-image{
      align-content: center;
      margin: 0 5px;
      .image-container{
        height:250px;
        margin:auto;
      }
      img{
        display:flex;
        margin:auto;
        height:240px;
        max-width: 320px;
      }
      .actions {
        position:relative;
      }
      .image-action.delete-image {
        cursor: pointer;
        position:absolute;
        top:3px;
        left:5px;
        height:24px;
        width:24px;
        display:flex;
        justify-content: center;
        i{
          color:#FFF;
          position:inherit;
        }
        i:hover{
          color:$alert-dark-red;
          height:27px;
          width:27px;
        }
      }
      .image-action.hide-image {
        cursor: pointer;
        position:absolute;
        top:3px;
        margin-left:90%;
        height:24px;
        width:24px;
        display:flex;
        justify-content: center;
        i{
          color:#FFF;
          position:inherit;
        }
        i:hover{
          color:$alert-dark-green;
          height:27px;
          width:27px;
        }
      }

      .image-action.download-image {
        cursor: pointer;
        position:absolute;
        top:3px;
        margin-left:43%;
        height:24px;
        width:24px;
        display:flex;
        justify-content: center;
        i{
          color:#FFF;
          position:inherit;
        }
        i:hover{
          color:#007bff;
        }
      }
    }
  }
}

@media only screen and (min-width: 992px) {
  .dashboard-item.side-bar {
    padding-left: 20px !important;
  }
}

@media only screen and (max-width: 968px){
  .dashboard-wrapper{
    .dashboard-tabs .tab-header{
      font-size: 10px;
      padding:0px 3px;
    }
  }
}

@media only screen and (max-width: 668px) {

  .dashboard-tabs .tab-header {
    font-size:8px;
    padding: 0 5px;
    text-align: center;
  }
  .dashboard-wrapper .tab-header-title {
    margin: 20px 10px;
  }
  .dashboard-tabs,
  .dashboard-tab-item .tab-content {
    margin: 0 10px;
  }
  .dashboard-wrapper{

  }
}

@media only screen and (max-width: 450px) {
  .content-container.admin-container{
    .date-switcher{
      padding:60px 0px 0px;
      .pagination{
        margin:0 10px;
      }
      .btn.btn-secondary.pagination{
        margin:0;
      }
      h1{
        font-size:18px;
      }
    }
    .dashboard-wrapper{
      .dashboard-tabs{
        display:block;
        flex-wrap:wrap;
        .tab-header{
          font-size:15px;
        }
      }
    }
  }
}
