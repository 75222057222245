
.vet-form-container{
  .actions{
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex: 1;
    .btn{
      margin: 5px;
    }
  }
  .vet-form{
    display: grid;
    grid-template-columns: .5fr .5fr;
    width: 100%;
    .basicInfo, .address{
      display: grid;
      grid-template-columns: .4fr .4fr;
      grid-column-gap: .1fr;
      .headerContainer{
        grid-column: span 2;
        margin-left: 20px;
      }
      .inputContainer{
        margin-left: 10px;
      }
    }
    
  }
}

.vetTable{
  flex: 1;
  flex-direction: 'row';
  .header, .vetLink{
    display: grid;
    grid-template-columns: .25fr .6fr .15fr;
    padding: 10px;
    border-bottom: 1px solid #c3c3c3;
  }
}