.pagination-container {
  display: flex;
  margin-top: 20px;
  margin-bottom: 10px;
  padding-left: 20px;
  justify-content: flex-end;
  align-content: center;


  ul {
    display: inline-flex;
    list-style-type: none;
    padding-left: 0;

    li {
      width: 34px;
      line-height: 34px;
      text-align: center;
      border-radius: 50%;
      background-color: transparent;
      color: $medium-brown;
      margin-right: 10px;
      border: 1px solid transparent;

      &:hover {
        cursor: pointer;
        background-color: $medium-blue;
        color: white;
      }

      &.active {
        background-color: $white;
        color: $gray-light;
        border: 1px solid $gray-lighter;
        font-weight: bold;

        &:hover {
          border-color: $medium-blue;
          background-color: $medium-blue;
          color: white;
        }
      }
    }
  }

  .pagination-btn-nav {
    line-height: 36px;
    height: 36px;
    font-size: 26px;
    color: $gray-light;
    font-weight: bold;

    &:hover {
      color: $medium-blue;
      cursor: pointer;
    }

    &.prev {
      margin-right:10px;
    }
  }
}
