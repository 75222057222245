.client-settings {
  &-container {
    width: 80%;
    margin: 0 auto;

    &.password-reset {
      padding-top: 20px;
    }
  }
  position: relative;
}

.client-appointment-list{
  max-height: 500px;
  overflow-y: scroll;
}

.editClientProfile{
  pointer-events: none;
  .input-wrapper--form-control-wrapper-component{
    border: none !important;
  }
}

.activateClient{
  text-align: center;
  button{
    margin: 10px;
  }
}

.appts-title{
  text-align: center;
}

.editProfileBtn{
  position: absolute;
  top: 10px;
  right: 10px;
}

.editProfileTemp{

}

.punch2{
  border: 1px solid $medium-blue;
  height: 200px;
  border-radius: 10px;
  padding: 25px;
  display: flex;
  flex: 1;
  flex-direction: column;
  justify-content: center;
}

.punchRow2{
  // height: 200px;
  display: flex;
  flex: 1;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}

.pawprint2{
  position: relative;
  border: 2px solid $medium-blue;
  border-radius: 50%;
  padding: 30px 35px;
  font-size: 16px;
  i{
    position: absolute;
    top: 33%;
    left: 30%;
    color: $dark-blue;
  }
}


.addressRow{
  padding-left: 24px;
  margin-bottom: 10px;
  pointer-events: all;
  height: 40px;
  width: 40% !important;
  h5{
    margin-bottom: 0;
  }
  span{
    color: black;
  }
}

.visit-credits {
  .creditRow{
    display:flex;
    justify-content: center;
  }
  .client-credits {
    text-align: center;
    border: 1px solid #3aafa9;
    border-collapse: collapse;
    border-spacing: 0px;

    .stats-container{
      width: 80%;
      margin:auto;
      .form-component-wrapper{
        max-width: 300px;
      }
      .margin-container {
        margin:auto;
        max-width: 300px;
        min-width: 220px;
        position:relative;
      }
    }

    .row.auto-renew{
      padding: 5px 0;

      i{
        margin: 3px;
      }

      .btn-danger {
        padding: 11px 18px 9px;
      }
    }
    .card-text {
      font-size: 12px;
      text-transform: uppercase;
      letter-spacing: .5px;
      font-weight: 100;
      color: #77736F;

      span {
        color: #3aafa9;
        font-weight: 600;
        font-size: 16px;
      }
    }
  }
  .form-component-wrapper {
    border: 1px solid #AFAFAF;
    padding: 15px 0;
    border-radius: 3px;
    margin: 5px;
    position:relative;
    label{
      position:absolute;
      top:0;
      left:0;
      color:#3aafa9;
    }
    input{
      position:relative;
      width:100%;
      height:24px;
    }
    input:focus {
      outline: 0;
    }
  }
  .btn {
    margin: 10px 0;
  }
  .has-error{
    border: 1px solid #a94442
  }
  .btn-danger{
    padding:11px 18px 9px;
  }
  .btn{
    margin:5px;
  }
}

.error-alert-div {
  margin-top: 15px;
}

.disabled-day{
  background-color: #e2e2e2;
}
.day-block{
  .pet-card-xtra-condensed{
    display:block;
    p{
      text-align: left;
      width:100%;
      .cal-label{
        font-weight:800;
      }
      font-size:.65em;
    }
  }
}



@media(max-width:1200px){
  body .client-credits {
    margin:auto;
    width:50%;
  }
}

@media(max-width:992px){
  body .client-credits {
    width: 75%;
    margin:auto;
  }
}

@media(max-width:768px){
  body .client-credits {
    width:100%;
  }
}
