.admin-settings{
  table.card-list{
    padding: 0;
    th{
      padding-top: 0;
    }
  }
}

.client-credits{
  .auto-renew-icon{
    color: $dark-blue;
    cursor: pointer;
    font-size: 18px;
  }
}

.marketForm{
  margin-left: 0;
  margin-right: 0;
  padding: 20px 57px;
  button{
    margin: 5px;
    height: 40px;
    float: right;
  }
  h3{
    cursor: pointer;
  }
  h3:hover{
    color: $dark-blue;
  }
}
.marketRow{
  padding-left: 40px;
  padding-right: 40px;
  margin: 5px 0;
  font-size: 18px;
  div{
    border: 1px solid $light-blue;

  }
}
// Zone Row (For Admin Settings Zone Detail/Edit Views)
.zone-row {
  background-color: $white;
  border: 1px solid $light-brown;
  border-radius: 2px;
  box-shadow: 0px 2px 4px $light-brown;
  margin: 5px 0;
  p {
    margin: 0;
    color: $black;
    font-size: 15px;
    line-height: 22px;
  }
}

.zone-row-top {
  display: flex;
  justify-content: space-between;
  padding: 12px;
  .zone-info{
    display: flex;
    align-items: center;
    p{
      padding-left: 20px;
    }
    .blank-zone-badge{
      height: 48px;
      width: 48px;
      border-radius: 96px;
      background-color: $light-brown;
    }
    .blank-zone-name{
      height: 22px;
      width: 140px;
      margin-left: 20px;
      background-color: $light-brown;
    }
  }
  .zone-actions {
    display: flex;
    align-items: center;
    .close-button{
      padding: 0px 4px 0px 18px;
    }
  }
}

.zone-row-details-header {
  text-transform: uppercase;
  color: $dark-brown;
  font-weight: 600;
  background-color: $light-brown;
  padding: 6px 12px 4px 12px;
  font-size: 13px;
}

.zone-form{
  display: flex;
  flex-direction: column;
  padding: 12px;
  .zone-inputs{
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    align-items: center;
    flex-wrap: wrap;
    flex-grow: 1;
    .zone-input{
      flex: 1;
      margin: 10px;
      min-width: 200px;
      max-width: 300px;
      &.color-fields{
        min-width: 300px;
      }
    }
  }
  .zone-buttons{
    display: flex;
    align-self: center;
    button{
      margin: 0 10px;
    }
    input{
      margin: 0 10px;
    }
  }
}
